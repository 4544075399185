import {getFullName} from '../helpers/user';
import {Base} from '../interfaces/base';
import {Order, ORDER_STATUS_ORDER, OrderStatus} from '../interfaces/order';
import {BaseAbstractModel} from './base.abstract';

export class OrderModel extends BaseAbstractModel<OrderModel> implements Order {
  public readonly storableProperties: (keyof OrderModel)[] = [
    'customerId',
    'userId',
    'user',
    'status',
    'reference',
    'positions',
    'address',
    'serviceFrequency',
    'constructionSiteNo',
    'toiletNo',
    'attachments',
    'comment',
    'shippedAt',
    'rentFrom',
    'rentTo',
    'createdAt',
    'updatedAt',
    'isUnsubscribed',
  ];
  public readonly searchableProperties: (keyof OrderModel)[] = [
    'id',
    'customerId',
    'userId',
    'user',
    'reference',
    'positions',
    'address',
    'constructionSiteNo',
    'toiletNo',
    'attachments',
    'comment',
    'shippedAt',
    'rentFrom',
    'rentTo',
    'createdAt',
    'updatedAt',
    'isUnsubscribed',
  ];
  public customerId: Order['customerId'];
  public userId: Order['userId'];
  public user: Order['user'];
  public status: Order['status'];
  public reference: Order['reference'];
  public positions: Order['positions'];
  public address: Order['address'];
  public serviceFrequency: Order['serviceFrequency'];
  public constructionSiteNo: Order['constructionSiteNo'];
  public toiletNo: Order['toiletNo'];
  public attachments: Order['attachments'];
  public comment: Order['comment'];
  public shippedAt: Order['shippedAt'];
  public rentFrom: Order['rentFrom'];
  public rentTo: Order['rentTo'];
  public createdAt: Order['createdAt'];
  public updatedAt: Order['updatedAt'];
  public isUnsubscribed: Order['isUnsubscribed'];

  public constructor(props: Partial<Base> & Order) {
    super(props);

    this.customerId = props.customerId;
    this.userId = String(props.userId);

    this.user = props.user;

    this.status = props.status;
    this.reference = props.reference;
    this.positions = props.positions;
    this.address = props.address;
    this.serviceFrequency = props.serviceFrequency;
    this.constructionSiteNo = props.constructionSiteNo;
    this.toiletNo = props.toiletNo;
    this.attachments = props.attachments;
    this.comment = props.comment;

    this.shippedAt = props.shippedAt;
    this.rentFrom = props.rentFrom;
    this.rentTo = props.rentTo;

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;

    // added 2022-12-27
    this.isUnsubscribed = props?.isUnsubscribed || false;
  }

  public get userFullName(): string {
    return getFullName({firstName: this.user?.firstName, lastName: this.user?.lastName});
  }

  public get statusOverall() {
    return (this.isUnsubscribed ? [this.status, OrderStatus.UNSUBSCRIBED] : [this.status]).sort(
      (a, b) => ORDER_STATUS_ORDER.indexOf(a) - ORDER_STATUS_ORDER.indexOf(b),
    )[0];
  }
}
