import {regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DatePicker, Form, Input, Modal, Typography} from 'antd';
import dayjs from 'dayjs';
import React, {FC, PropsWithChildren, useState} from 'react';
import {useTranslation} from 'react-i18next';
import type {Moment} from 'moment';

import {OrderStatus} from '@wc-vermietung/library/interfaces/order';
import {UnsubscribeFacilityPartialDocument} from '@wc-vermietung/library/interfaces/unsubscribe-facility';
import {DATE_FORMAT} from '@wc-vermietung/library/lib/date';
import {UnsubscribeFacilityModel} from '@wc-vermietung/library/models/unsubscribe-facility';
import {UnsubscribeFacilityRepository} from '@wc-vermietung/library/repositories/client/unsubscribe-facility';

import {AmountOfCabinsSelectOptions} from '../../../../../components/AmountOfCabinsSelectOptions';
import {Button} from '../../../../../components/Button';
import {AddressFormItem} from '../../../../../components/form/AddressFormItem';
import {FormItem} from '../../../../../components/form/FormItem';
import {useAuth} from '../../../../../hooks/useAuth';
import {useRepository} from '../../../../../hooks/useRepository';
import {useStore} from '../../../../../hooks/useStore';
import {openNotification} from '../../../../../utils/notification.helper';
import {REGEX} from '../../../../../utils/regex.helper';

interface UnsubscribeFacilityForm
  extends Omit<
    UnsubscribeFacilityPartialDocument<Date>,
    'readyForPickupFrom' | 'pickedUpAtTheLatest' | 'orderId' | 'customerId' | 'filledOutById'
  > {
  readyForPickupBetween: [Moment, Moment];
}

interface UnsubscribeFacilityModalProps {
  orderId: string;
}

export const UnsubscribeFacilityModalButton: FC<PropsWithChildren<UnsubscribeFacilityModalProps>> = ({orderId}) => {
  const {t} = useTranslation();
  const {isOrdersLoading, orders, isCustomersLoading, customers, reloadOrders} = useStore();
  const {user} = useAuth();

  const orderRepository = useRepository('order');

  const [visible, setVisible] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [form] = Form.useForm<UnsubscribeFacilityForm>();

  const order = orders.find(({id}) => id === orderId);
  const customer = order && customers.find(({id}) => id === order.customerId);

  if (!(user && order && customer) || order.statusOverall !== OrderStatus.DELIVERED) {
    return null;
  }

  const unsubscribeFacility = async (partialDocument: UnsubscribeFacilityPartialDocument<Date>) => {
    setInProgress(true);

    await new UnsubscribeFacilityRepository().create(new UnsubscribeFacilityModel({...partialDocument, createdAt: new Date()}));

    await orderRepository.markAsUnsubscribed(orderId);

    reloadOrders();
  };

  const onClose = () => {
    setVisible(false);
  };

  const onSubmit = () => {
    void form
      .validateFields()
      .then((v) =>
        unsubscribeFacility({
          ...v,
          readyForPickupFrom: new Date(v.readyForPickupBetween[0].toDate()),
          pickedUpAtTheLatest: new Date(v.readyForPickupBetween[1].toDate()),
          orderId,
          filledOutById: user.id,
          customerId: customer.id,
        }),
      )
      .then(onClose)
      .then(() => {
        openNotification({type: 'success', message: t('message.unsubscribe_facility.successfully_unsubscribed')});
      });
  };

  return (
    <>
      <Button className={'text-xs'} loading={isOrdersLoading || isCustomersLoading} hasIcon onClick={() => setVisible(true)}>
        <FontAwesomeIcon icon={regular('power-off')} />
        {t('actions.unsubscribe_facility')}
      </Button>

      <Modal
        visible={visible}
        title={t('modal.unsubscribe_facility.title')}
        okText={t('actions.unsubscribe')}
        cancelText={t('actions.cancel')}
        onOk={onSubmit}
        onCancel={onClose}
        afterClose={() => {
          setInProgress(false);
          form.resetFields();
        }}
        confirmLoading={inProgress}
        destroyOnClose={true}
        width={'780px'}
      >
        <Form
          form={form}
          layout={'horizontal'}
          requiredMark={'optional'}
          labelAlign={'left'}
          className={'ant-form-fix-item-label-colon'}
          initialValues={{
            pickupAddress: {...order.address, name: ''},
            amountOfCabins: 1, // By definition, there should always be only 1 facility per order
            toiletNo: order.toiletNo,
            constructionSiteNo: order.constructionSiteNo,
            filledOutByName: user.fullName,
            customerName: customer.name,
            email: user.email,
            note: '',
          }}
          labelCol={{span: 8}}
          wrapperCol={{flex: 1}}
        >
          <AddressFormItem formItemParentName={'pickupAddress'} header={t('view.unsubscribe_facility_modal.form.pickup_address')} />

          <Typography.Paragraph className={'font-medium mb-0 pt-2'}>
            {t('view.unsubscribe_facility_modal.form.additional_details')}
          </Typography.Paragraph>
          <FormItem
            label={t('view.unsubscribe_facility_modal.form.ready_for_pickup_between')}
            name={'readyForPickupBetween'}
            rules={[{required: true}]}
          >
            <DatePicker.RangePicker
              className={'flex'}
              disabledDate={(date) => dayjs(date.toDate()).isSameOrBefore(dayjs())}
              format={DATE_FORMAT.DATE}
            />
          </FormItem>
          <FormItem label={t('view.unsubscribe_facility_modal.form.amount_of_cabins')} name={'amountOfCabins'} rules={[{required: true}]}>
            <AmountOfCabinsSelectOptions />
          </FormItem>
          <FormItem label={t('view.unsubscribe_facility_modal.form.toilet_no')} name={'toiletNo'} rules={[{required: true}]}>
            <Input disabled />
          </FormItem>
          <FormItem label={t('view.unsubscribe_facility_modal.form.construction_site_no')} name={'constructionSiteNo'}>
            <Input disabled />
          </FormItem>
          <FormItem
            label={t('view.unsubscribe_facility_modal.form.filled_out_by_name')}
            name={'filledOutByName'}
            rules={[{required: true}]}
          >
            <Input disabled />
          </FormItem>
          <FormItem label={t('view.unsubscribe_facility_modal.form.customer_name')} name={'customerName'} rules={[{required: true}]}>
            <Input disabled />
          </FormItem>
          <FormItem label={t('person.mobile')} name={'mobile'} rules={[{required: true}]}>
            <Input />
          </FormItem>
          <FormItem
            label={t('person.email')}
            name={'email'}
            rules={[{required: true}, {pattern: REGEX.email, message: t('form.errors.invalid_email')}]}
          >
            <Input disabled />
          </FormItem>
          <FormItem label={t('view.unsubscribe_facility_modal.form.note')} name={'note'}>
            <Input.TextArea showCount maxLength={255} />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
