import {QueryDocumentSnapshot} from '@firebase/firestore';

import {StatisticDocument} from '../../interfaces/statistic';
import {StatisticModel} from '../../models/statistic';
import {BaseRepository} from './base';

export const COLLECTION_PATH_STATISTICS = 'statistics';
export const DOC_ID_CUSTOMER_STATISTICS = 'customer';

export class StatisticRepository<STATS> extends BaseRepository<StatisticDocument<STATS>, StatisticModel<STATS>> {
  public constructor() {
    super({collectionPath: COLLECTION_PATH_STATISTICS});
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<StatisticDocument<STATS>>): StatisticModel<STATS> {
    const snapshotData = snapshot.data();

    return new StatisticModel<STATS>({
      ...snapshotData,
      id: snapshot.id,

      createdAt: snapshotData.createdAt?.toDate(),
      updatedAt: snapshotData.updatedAt?.toDate(),
    });
  }

  public getAll(): Promise<StatisticModel<STATS>[]> {
    throw new Error('Method not available.');
  }
}
