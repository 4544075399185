import React, {FC} from 'react';
import {useSearchParams} from 'react-router-dom';

import {BadRequest} from '../../error/BadRequest';
import {ResetPassword} from './partials/ResetPassword';

export const AccountManagement: FC = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  if (!mode || !['resetPassword'].includes(mode)) {
    return <BadRequest />;
  }

  if (!oobCode) {
    return <BadRequest />;
  }

  return <>{mode === 'resetPassword' && <ResetPassword oobCode={oobCode}></ResetPassword>}</>;
};
