import {message} from 'antd';
import {ArgsProps} from 'antd/lib/message';

interface MessageProps<CONTENT> extends Omit<ArgsProps, 'content'> {
  content: CONTENT;
  closeOnClick?: boolean;
}

export const openMessage = <CONTENT>({key, duration = 20, closeOnClick = true, ...args}: MessageProps<CONTENT>) => {
  const notifyKey = key;

  return message.open({
    key,
    duration,
    onClick: () => closeOnClick && message.destroy(notifyKey),
    ...args,
  });
};
