import React, {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {useLang} from '../../hooks/useLang';
import {AppRoutes} from '../../utils/route.helper';
import {NotFound} from '../error/NotFound';
import {AccountManagement} from './account-management/AccountManagement';
import {Login} from './login/Login';

export const AuthRoutes: FC = () => {
  const lang = useLang();

  return (
    <Routes>
      <Route path={'*'} element={<NotFound />} />
      <Route index element={<Navigate to={AppRoutes.auth.login({lang})} replace />} />

      <Route path={'/login'} element={<Login />} />

      <Route path={'account-management'} element={<AccountManagement />} />
    </Routes>
  );
};
