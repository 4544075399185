import {FirebaseError} from '@firebase/util';
import {Alert, Form, Input} from 'antd';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../../../components/Button';
import {FullScreenLoader} from '../../../../components/FullScreenLoader';
import {DefaultLayout} from '../../../../components/layouts/DefaultLayout';
import {useLang} from '../../../../hooks/useLang';
import {useRepository} from '../../../../hooks/useRepository';
import {FirebaseAuthErrorCodes} from '../../../../types/firebase';
import {getInvalidFieldNames} from '../../../../utils/form.helper';
import {openMessage} from '../../../../utils/message.helper';
import {REGEX} from '../../../../utils/regex.helper';
import {AppRoutes} from '../../../../utils/route.helper';
import {BadRequest} from '../../../error/BadRequest';

interface ResetPasswordForm {
  newPassword: string;
  newPasswordConfirm: string;
}

interface ResetPasswordProps {
  oobCode: string;
}

export const ResetPassword: FC<ResetPasswordProps> = ({oobCode}: ResetPasswordProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const lang = useLang();
  const authRepository = useRepository('auth');

  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const [firebaseErrorCode, setFirebaseErrorCode] = useState<FirebaseAuthErrorCodes>();

  const [resetTokenVerified, setResetTokenVerified] = useState(false);
  const [invalidOobCode, setInvalidOobCode] = useState(false);
  const [email, setEmail] = useState<string>();

  const [form] = Form.useForm<ResetPasswordForm>();
  Form.useWatch([], form);

  useEffect(() => {
    const invalidFields = getInvalidFieldNames(form);

    if (invalidFields.length) {
      void form.validateFields(invalidFields);
    }
  }, [form, lang]);

  useEffect(() => {
    if (!resetTokenVerified) {
      authRepository
        .verifyPasswordResetCode({oobCode})
        .then((email) => {
          setResetTokenVerified(true);
          setEmail(email);
        })
        .catch(() => {
          setInvalidOobCode(true);
          setResetTokenVerified(true);
        });
    }
  }, [authRepository, resetTokenVerified, invalidOobCode, oobCode]);

  if (!resetTokenVerified) {
    return <FullScreenLoader />;
  }

  if (!oobCode || invalidOobCode) {
    return <BadRequest />;
  }

  const onResetPassword = ({newPassword}: ResetPasswordForm) => {
    setResetPasswordInProgress(true);
    authRepository
      .confirmPasswordReset({oobCode, newPassword})
      .then(() => {
        void openMessage({
          type: 'success',
          content: t('message.password_has_been_reset'),
          key: 'user-password-reset',
        });

        return navigate(AppRoutes.user.me({lang}));
      })
      .catch((error: unknown) => {
        console.error('confirmPasswordReset', error);

        if (error instanceof FirebaseError) {
          const firebaseErrorCode = error.code as FirebaseAuthErrorCodes;

          switch (firebaseErrorCode) {
            // t('firebase.errors.auth/wrong-password')
            case 'auth/wrong-password':
              form.setFields([{name: 'currentPassword', errors: [t(`firebase.errors.${error.code}`)]}]);
              break;

            // t('firebase.errors.auth/weak-password')
            case 'auth/weak-password':
              form.setFields([{name: 'newPassword', errors: [t(`firebase.errors.${error.code}`)]}]);
              break;

            default:
              setFirebaseErrorCode(firebaseErrorCode);
          }
        }
      })
      .finally(() => setResetPasswordInProgress(false));
  };

  const onResetPasswordFailed = (errorInfo: ValidateErrorEntity<ResetPasswordForm>) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <DefaultLayout pageTitle={t('actions.reset_password')} hideBreadcrumb>
      <Form
        form={form}
        onFinish={onResetPassword}
        onFinishFailed={onResetPasswordFailed}
        className={'max-w-sm'}
        layout={'vertical'}
        requiredMark={false}
      >
        <Form.Item hidden name={'email'} initialValue={email}>
          <Input autoComplete={'username'} />
        </Form.Item>

        <Form.Item
          label={t('form.fields.new_password')}
          name={'newPassword'}
          rules={[
            {required: true, message: t('form.errors.new_password_missing')},
            {
              pattern: REGEX.password,
              message: t('form.errors.password_weak'),
            },
          ]}
        >
          <Input.Password autoComplete={'new-password'} />
        </Form.Item>

        <Form.Item
          label={t('form.fields.new_password_confirm')}
          name={'newPasswordConfirm'}
          dependencies={['new-password']}
          rules={[
            ({getFieldValue}) => ({
              validator: (_, value) => {
                if (!getFieldValue('newPassword')) {
                  return Promise.reject(new Error(t('form.errors.new_password_for_confirmation_missing')));
                }

                if (!value) {
                  return Promise.reject(new Error(t('form.errors.new_password_confirmation_missing')));
                }

                if (getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('form.errors.new_password_confirmation_mismatched')));
              },
            }),
          ]}
        >
          <Input.Password autoComplete={'new-password'} />
        </Form.Item>

        <Form.Item className={'mt-16 mb-0'}>
          <Button htmlType={'submit'} className={'font-medium px-7'} loading={resetPasswordInProgress} disabled={resetPasswordInProgress}>
            {t('actions.reset_password')}
          </Button>
        </Form.Item>

        {firebaseErrorCode && (
          <Alert
            className={'mt-8'}
            message={t(`firebase.errors.${firebaseErrorCode}`)}
            type={'error'}
            onClose={() => setFirebaseErrorCode(undefined)}
            showIcon
            closable
          />
        )}
      </Form>
    </DefaultLayout>
  );
};
