import {Space, Table} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {groupBy} from '@wc-vermietung/library/helpers/group-by';
import {hasValueInObject} from '@wc-vermietung/library/helpers/has-value-in-object';
import {AntdNumericalSortDirections, sortAlphabetical, sorter, sortNumerically} from '@wc-vermietung/library/helpers/sorters';
import {CustomerStatistic} from '@wc-vermietung/library/interfaces/statistic/customer-statistic';

import {DefaultLayout} from '../../../components/layouts/DefaultLayout';
import {SearchInput} from '../../../components/SearchInput';
import {useStore} from '../../../hooks/useStore';
import {generateTableColumns, getDataIndexList, TableColumn, TableRow} from '../../../utils/table.helper';

interface CustomerStatisticExtended extends CustomerStatistic {
  fullData?: string;
}

type CustomerListTableData = CustomerStatisticExtended;
type CustomerListTableRow = TableRow<CustomerListTableData>;
type CustomerListTableColumn = TableColumn<CustomerListTableData>;

export const CustomerList: FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {isCustomerStatisticLoading, customerStatistic, isLoading, users, customers, orders} = useStore();

  const [searchValue, setSearchValue] = useState<string>('');

  const [customerStatisticExtended, setCustomerStatisticExtended] = useState<CustomerStatisticExtended[]>([]);

  useEffect(() => {
    if (isCustomerStatisticLoading) {
      return;
    }

    const statistics = Object.values(customerStatistic?.data || {});
    setCustomerStatisticExtended(statistics);

    if (isLoading) {
      return;
    }

    const customersById = groupBy({data: customers, groupKey: 'id'});
    const usersByCustomerId = groupBy({data: users, groupKey: 'customerId'});
    const ordersByCustomerId = groupBy({data: orders, groupKey: 'customerId'});

    setCustomerStatisticExtended(
      statistics.map<CustomerStatisticExtended>((statistic) => ({
        ...statistic,
        fullData: [
          ...new Set(
            [customersById, usersByCustomerId, ordersByCustomerId].map((grouped) =>
              grouped?.[statistic.id]?.map((model) => model.toString()),
            ),
          ),
        ].join(' '),
      })),
    );
  }, [isCustomerStatisticLoading, isLoading, customerStatistic, customers, orders, users]);

  const fields: CustomerListTableColumn[] = [
    {
      // t('customers.list.table.name')
      dataIndex: 'name',
      column: {
        defaultSortOrder: 'ascend',
        sorter: sorter<CustomerListTableRow>('name', sortAlphabetical),
      },
    },
    {
      // t('customers.list.table.amount_location')
      dataIndex: 'amountLocation',
      column: {
        sorter: sorter<CustomerListTableRow>('amountLocation', sortNumerically),
        sortDirections: AntdNumericalSortDirections,
      },
    },
    {
      // t('customers.list.table.amount_non_returned_facility')
      dataIndex: 'amountNonReturnedFacility',
      column: {
        sorter: sorter<CustomerListTableRow>('amountNonReturnedFacility', sortNumerically),
        sortDirections: AntdNumericalSortDirections,
      },
    },
    {
      // t('customers.list.table.amount_orders')
      dataIndex: 'amountOrders',
      column: {
        sorter: sorter<CustomerListTableRow>('amountOrders', sortNumerically),
        sortDirections: AntdNumericalSortDirections,
      },
    },
    {
      // t('customers.list.table.amount_users')
      dataIndex: 'amountUsers',
      column: {
        sorter: sorter<CustomerListTableRow>('amountUsers', sortNumerically),
        sortDirections: AntdNumericalSortDirections,
      },
    },
  ];

  const columns = generateTableColumns({fields, transPrefix: 'customers.list.table'});
  const dataIndexList = getDataIndexList(fields);
  const dataSource: CustomerListTableRow[] = customerStatisticExtended
    .filter((statistic) =>
      searchValue
        ? hasValueInObject<CustomerStatisticExtended, keyof CustomerStatisticExtended>({
            value: searchValue,
            obj: statistic,
            objProps: ['fullData'],
          })
        : true,
    )
    .sort(sorter<CustomerStatisticExtended>('name', sortAlphabetical))
    .map((model) =>
      dataIndexList.reduce<CustomerListTableRow>((curr, dataIndex) => ({...curr, [dataIndex]: model[dataIndex]}), {
        key: model.id,
      } as CustomerListTableRow),
    );

  return (
    <DefaultLayout pageTitle={t('customers.list.title')}>
      <Space className={'flex'} direction={'vertical'} size={40}>
        <SearchInput onChange={(event) => setSearchValue(event.target.value)} isLoading={isLoading} />
        <Table
          className={'customers'}
          rowClassName={'cursor-pointer'}
          size={'small'}
          columns={columns}
          dataSource={dataSource}
          scroll={{x: 'max-content'}}
          onRow={(record: CustomerListTableRow) => ({
            onClick: () => navigate(String(`${String(record.key)}/orders`)),
          })}
          loading={isCustomerStatisticLoading}
          pagination={{
            showTotal: (total, range) => t('pagination.of_items', {start: range[0], end: range[1], total}),
          }}
        />
      </Space>
    </DefaultLayout>
  );
};
