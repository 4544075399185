import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(isToday);

export enum DATE_FORMAT {
  DATE_AND_TIME = 'DD.MM.YYYY HH:mm:ss',
  DATE = 'DD.MM.YYYY',
  FULL_DATE_WRITTEN = 'LL',
  YYYY_MM_DD = 'YYYY-MM-DD',
}
