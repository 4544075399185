import {valueToString} from './value-to-string';

export const hasValueInObject = <T extends object, P extends keyof T>({
  value,
  obj,
  objProps,
}: {
  value: string;
  obj: T;
  objProps: P[];
}): boolean => {
  const searchValues = value.split(' ').filter((v) => v);

  return (
    objProps
    && searchValues.filter((searchValue) =>
      objProps
        .map((prop) => valueToString(obj?.[prop]).toLowerCase().trim())
        .filter((propValue) => propValue)
        .find((propValue) => propValue?.includes(searchValue.toLowerCase())),
    ).length === searchValues.length
  );
};
