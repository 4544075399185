import {Base} from '../interfaces/base';
import {NewOrder} from '../interfaces/new-order';
import {BaseAbstractModel} from './base.abstract';

export class NewOrderModel extends BaseAbstractModel<NewOrderModel> implements NewOrder {
  public readonly storableProperties: (keyof NewOrderModel)[] = [
    'deliveryAddress',
    'desiredDeliveryDate',
    'amountOfCabins',
    'serviceFrequency',
    'contactPersonName',
    'contactPersonMobile',
    'constructionSiteNo',
    'estimatedRentalPeriod',
    'billingAddress',
    'sendInvoiceToAddress',
    'filledOutById',
    'filledOutByName',
    'filledOutByEmail',
    'filledOutByMobile',
    'customerId',
    'customerName',
    'note',
    'attachments',
    'createdAt',
  ];
  public deliveryAddress: NewOrder['deliveryAddress'];
  public desiredDeliveryDate: NewOrder['desiredDeliveryDate'];
  public amountOfCabins: NewOrder['amountOfCabins'];
  public serviceFrequency: NewOrder['serviceFrequency'];
  public contactPersonName: NewOrder['contactPersonName'];
  public contactPersonMobile: NewOrder['contactPersonMobile'];
  public constructionSiteNo: NewOrder['constructionSiteNo'];
  public estimatedRentalPeriod: NewOrder['estimatedRentalPeriod'];
  public billingAddress: NewOrder['billingAddress'];
  public sendInvoiceToAddress: NewOrder['sendInvoiceToAddress'];
  public filledOutById: NewOrder['filledOutById'];
  public filledOutByName: NewOrder['filledOutByName'];
  public filledOutByEmail: NewOrder['filledOutByEmail'];
  public filledOutByMobile: NewOrder['filledOutByMobile'];
  public customerId: NewOrder['customerId'];
  public customerName: NewOrder['customerName'];
  public note: NewOrder['note'];
  public attachments: NewOrder['attachments'];
  public createdAt: NewOrder['createdAt'];

  public constructor(props: Partial<Base> & NewOrder) {
    super(props);

    this.deliveryAddress = props.deliveryAddress;
    this.desiredDeliveryDate = props.desiredDeliveryDate;
    this.amountOfCabins = props.amountOfCabins;
    this.serviceFrequency = props.serviceFrequency;
    this.contactPersonName = props.contactPersonName;
    this.contactPersonMobile = props.contactPersonMobile;
    this.constructionSiteNo = props.constructionSiteNo;
    this.estimatedRentalPeriod = props.estimatedRentalPeriod;
    this.billingAddress = props.billingAddress;
    this.sendInvoiceToAddress = props.sendInvoiceToAddress;
    this.filledOutByName = props.filledOutByName;
    this.filledOutById = props.filledOutById;
    this.filledOutByEmail = props.filledOutByEmail;
    this.filledOutByMobile = props.filledOutByMobile;
    this.customerName = props.customerName;
    this.customerId = props.customerId;
    this.note = props.note;
    this.attachments = props.attachments;

    this.createdAt = props.createdAt;
  }
}
