import {Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import React, {FC} from 'react';

type AmountOfCabinsType = number;

interface AmountOfCabinsProps extends Pick<SelectProps<AmountOfCabinsType>, 'value' | 'onChange'> {
  max?: AmountOfCabinsType;
}

export const AmountOfCabinsSelectOptions: FC<AmountOfCabinsProps> = ({max = 10, ...formProps}) => (
  <Select<AmountOfCabinsType> {...formProps}>
    {Array.from<unknown, AmountOfCabinsType>({length: max}, (_, i) => i + 1).map((v) => (
      <Select.Option value={v} key={v}>
        {v}
      </Select.Option>
    ))}
  </Select>
);
