import {Input, InputProps} from 'antd';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface SearchInputProps extends InputProps {
  isLoading?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({isLoading, ...props}) => {
  const {t} = useTranslation();

  return (
    <Input className={'flex max-w-[60%] sm:max-w-[300px]'} placeholder={isLoading ? t('app.loading') : t('actions.search')} {...props} />
  );
};
