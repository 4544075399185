import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space, Table, Typography} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import objectHash from 'object-hash';
import {RenderExpandIconProps} from 'rc-table/lib/interface';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {groupBy} from '@wc-vermietung/library/helpers/group-by';
import {sortAlphabetical} from '@wc-vermietung/library/helpers/sorters';
import {OrderStatus} from '@wc-vermietung/library/interfaces/order';
import {UserRole} from '@wc-vermietung/library/interfaces/user';

import './OrderList.scss';
import {EmDash} from '../../../../components/EmDash';
import {DefaultLayout} from '../../../../components/layouts/DefaultLayout';
import {OrderStatusTag} from '../../../../components/OrderStatusTag';
import {SearchInput} from '../../../../components/SearchInput';
import {useAuth} from '../../../../hooks/useAuth';
import {useStore} from '../../../../hooks/useStore';
import {generateTableColumns, TableColumn, TableRow} from '../../../../utils/table.helper';
import {NewOrderModalButton} from './partials/NewOrderModalButton';

interface RecordLevel1Type {
  constructionSiteNo: string;
  product: string;
  toiletNo: string;
  productCode: string;
  user: string;
  status: string;
}

interface RecordType {
  children?: (Omit<RecordType, 'children'> & RecordLevel1Type)[];

  id: string;
  reference: string;
}

type OrderListTableData = RecordType & Partial<RecordLevel1Type>;
type UserListTableRow = TableRow<OrderListTableData>;
type OrderListTableColumn = TableColumn<OrderListTableData>;

const isOrder = (record: OrderListTableData | RecordLevel1Type): record is RecordLevel1Type => 'product' in record;
const getResponsibleFullName = (fullName?: string) => fullName || EmDash;

export const OrderList: FC = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {customerId} = useParams();

  const {isOrdersLoading, isCustomersLoading, orders, customers} = useStore();

  const [tableFilter, setTableFilter] = useState<Record<string, FilterValue | null>>({});
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredOrders = orders
    .filter((order) => {
      switch (user?.role) {
        case UserRole.SUPERADMIN:
        case UserRole.ADMIN:
          return order.customerId === customerId;

        case UserRole.SUPERVISOR:
          return order.customerId === String(user?.customerId);

        default:
          return order.customerId === String(user?.customerId) && String(order.userId) === String(user?.id);
      }
    })
    .filter((order) => (searchValue ? order.hasValue(searchValue) : true));

  const locations = groupBy({data: filteredOrders, groupKey: 'reference'});

  const fields: OrderListTableColumn[] = [
    {dataIndex: 'reference'},
    {dataIndex: 'constructionSiteNo'},
    {dataIndex: 'toiletNo'},
    {dataIndex: 'product', transSuffix: 'position.name'},
    {dataIndex: 'productCode', transSuffix: 'position.code'},
    {
      dataIndex: 'user',
      column: {
        filterSearch: true,
        filteredValue: tableFilter?.user,
        filters: Object.keys(groupBy({data: filteredOrders, groupKey: 'userFullName'}))
          .sort(sortAlphabetical)
          .map((userFullName) => ({
            text: userFullName || <Typography.Text className={'italic'}>{t('filters.order.without_responsible')}</Typography.Text>,
            value: getResponsibleFullName(userFullName),
          })),
        onFilter: (value: string | number | boolean, record: OrderListTableData) =>
          record.children?.some((order) => order.user === value) || false,
      },
    },
    {
      dataIndex: 'status',
      column: {
        align: 'right',
        width: '15%',
        render: (value: string) => value && <OrderStatusTag orderStatus={value as OrderStatus} />,
        filterSearch: true,
        filteredValue: tableFilter?.status,
        filters: Object.values(OrderStatus).map((status) => ({text: t(`models.order.status_type.${status}`), value: status})),
        onFilter: (value: string | number | boolean, record: OrderListTableData) =>
          record.children?.some((order) => order.status === value) || false,
      },
    },
  ];

  const columns = generateTableColumns({fields, transPrefix: 'models.order'});

  const dataSource: UserListTableRow[] = Object.entries(locations).map(([reference, orders], i) => ({
    id: String(i),
    key: i,
    reference,
    children: orders.map((order) => ({
      id: order.id,
      key: `${i}_${order.id}`,
      reference,
      constructionSiteNo: order.constructionSiteNo,
      toiletNo: order.toiletNo,
      product: order.positions[0].name,
      productCode: order.positions[0].code,
      status: order.statusOverall,
      user: getResponsibleFullName(order?.userFullName),
    })),
  }));

  const isDataLoading = isOrdersLoading || isCustomersLoading;
  const isTableFilterActive = Object.entries(tableFilter || {}).some(([, value]) => value?.length);

  return (
    <DefaultLayout
      pageTitle={
        t('orders.list.title')
        + ((!user?.isAdmin
          ? user?.isSupervisor
            ? ` ${EmDash} ${String(user?.customerName)}`
            : ` ${EmDash} ${String(user?.fullName)}`
          : customers.length && ` ${EmDash} ${String(customers.find(({id}) => id === customerId)?.name)}`) || '')
      }
    >
      <Space className={'flex'} direction={'vertical'} size={40}>
        <SearchInput onChange={(event) => setSearchValue(event.target.value)} isLoading={isDataLoading} />
        <Table
          title={() => (
            <div className={'flex flex-row items-end justify-between mb-4'}>
              <Typography.Text className={'text-black/85'}>{t('orders.table.header.title')}:</Typography.Text>

              <NewOrderModalButton customerId={customerId} />
            </div>
          )}
          className={'locations'}
          size={'small'}
          columns={columns}
          dataSource={dataSource}
          scroll={{x: 'max-content'}}
          expandable={{
            expandIcon: (props: RenderExpandIconProps<UserListTableRow>) => (
              <Typography.Link className={'mr-[8px]'}>
                <FontAwesomeIcon
                  icon={solid('angle-right')}
                  onClick={(e) => props.onExpand(props.record, e as unknown as React.MouseEvent<HTMLElement>)}
                  className={[
                    'inline-flex',
                    'transition-transform',
                    'duration-200',
                    props.expanded ? 'rotate-90' : '',
                    props.record?.children?.length ? 'visible' : isOrder(props.record) ? 'hidden' : 'invisible',
                  ]
                    .filter((v) => v)
                    .join(' ')}
                />
              </Typography.Link>
            ),
            rowExpandable: (record: OrderListTableData) => !!record.children?.length,
            defaultExpandAllRows: isTableFilterActive || !!searchValue,
          }}
          onRow={(record: OrderListTableData) => ({
            onClick: () => isOrder(record) && navigate(record.id),
          })}
          loading={isDataLoading}
          pagination={{
            showTotal: (total, range) => t('pagination.of_items', {start: range[0], end: range[1], total}),
          }}
          onChange={(pagination, filters) => {
            setTableFilter(filters);
          }}
          key={`order-list-${objectHash(tableFilter)}-${searchValue}`}
        />
      </Space>
    </DefaultLayout>
  );
};
