import {FirebaseApp, getApp} from '@firebase/app';
import {getFunctions, httpsCallable} from '@firebase/functions';
import {paramCase} from 'change-case';

import {RetrieveDocumentResponseBody} from '../../interfaces/cloud-functions/functions/data-unit/retrieve-document';
import {GenerateCustomerStatisticResponseBody} from '../../interfaces/cloud-functions/functions/generate-customer-statistic';
import {ImportDataResponseBody} from '../../interfaces/cloud-functions/functions/import-data';
import {Regions} from '../../interfaces/cloud-functions/regions';
import {DEFAULT_EMULATOR_REGION, DEFAULT_REGION} from './region';

export enum CloudFunction {
  importData = 'importData',
  generateCustomerStatistic = 'generateCustomerStatistic',
  retrieveDocument = 'retrieveDocument',
}

export interface CloudFunctionTypes {
  [CloudFunction.importData]: {RESPONSE_BODY: ImportDataResponseBody; REQUEST_DATA: unknown};
  [CloudFunction.generateCustomerStatistic]: {RESPONSE_BODY: GenerateCustomerStatisticResponseBody; REQUEST_DATA: unknown};
  [CloudFunction.retrieveDocument]: {RESPONSE_BODY: RetrieveDocumentResponseBody; REQUEST_DATA: unknown};
}

type CloudFunctionConfigType = {
  [NAME in CloudFunction]: {nameHuman: string; name: CloudFunction; region: Regions[0]; type: 'https.onRequest' | 'https.onCall'};
};

export const CLOUD_FUNCTION_CONFIG: CloudFunctionConfigType = {
  [CloudFunction.importData]: {
    name: CloudFunction.importData,
    nameHuman: paramCase(CloudFunction.importData),
    region: DEFAULT_REGION,
    type: 'https.onRequest',
  },
  [CloudFunction.generateCustomerStatistic]: {
    name: CloudFunction.generateCustomerStatistic,
    nameHuman: paramCase(CloudFunction.generateCustomerStatistic),
    region: DEFAULT_REGION,
    type: 'https.onRequest',
  },
  [CloudFunction.retrieveDocument]: {
    name: CloudFunction.retrieveDocument,
    nameHuman: paramCase(CloudFunction.retrieveDocument),
    region: DEFAULT_REGION,
    type: 'https.onRequest',
  },
};

export const getCallableCloudFunction = <FUNCTION_NAME extends CloudFunction>({app, name}: {app?: FirebaseApp; name: FUNCTION_NAME}) => {
  const cloudFunctionConfig = CLOUD_FUNCTION_CONFIG[name];

  const functions = getFunctions(
    app || getApp(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST ? DEFAULT_EMULATOR_REGION : cloudFunctionConfig.region,
  );

  return httpsCallable<CloudFunctionTypes[FUNCTION_NAME]['REQUEST_DATA'], CloudFunctionTypes[FUNCTION_NAME]['RESPONSE_BODY']>(
    functions,
    cloudFunctionConfig.name,
  );
};
