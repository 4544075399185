import {Form, Input, Typography} from 'antd';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {REGEX} from '../../utils/regex.helper';
import {FormItem} from './FormItem';

interface AddressFormItemProps {
  formItemParentName: string;
  header?: string;
  fullyOptional?: boolean;
}

export const AddressFormItem: FC<AddressFormItemProps> = ({formItemParentName, header, fullyOptional}) => {
  const {t} = useTranslation();

  return (
    <>
      {header && <Typography.Paragraph className={'font-medium mb-0 mt-4'}>{header}</Typography.Paragraph>}
      <FormItem label={t('address.name')} name={[formItemParentName, 'name']}>
        <Input />
      </FormItem>
      <FormItem label={t('address.street')} name={[formItemParentName, 'street']} rules={[{required: !fullyOptional}]}>
        <Input />
      </FormItem>
      <Form.Item label={t('address.zip_city')} requiredMark={'optional'} wrapperCol={{flex: 0}} required={!fullyOptional}>
        <Input.Group className={'flex gap-2'}>
          <Form.Item
            label={t('address.zip')}
            name={[formItemParentName, 'zip']}
            rules={[{required: !fullyOptional}, {pattern: REGEX.zip, message: t('form.errors.invalid_zip')}]}
            noStyle
          >
            <Input className={'flex-[0_0_60px]'} />
          </Form.Item>
          <Form.Item label={t('address.city')} name={[formItemParentName, 'city']} rules={[{required: !fullyOptional}]} noStyle>
            <Input className={'flex-auto'} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </>
  );
};
