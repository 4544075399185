import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography} from 'antd';
import React, {FC} from 'react';

import {useAuth} from '../hooks/useAuth';
import {LanguageSwitcher} from './LanguageSwitcher';
import {ProfileDropdownMenu} from './ProfileDropdownMenu';

export const AvatarBlock: FC = () => {
  const {user} = useAuth();

  return (
    <div className={'flex gap-x-2.5 sm:gap-x-6 center-v-h'}>
      {user && (
        <ProfileDropdownMenu>
          <div className={'flex gap-x-2.5 center-v-h'}>
            <div className={'gap-x-2 flex-row center-v-h flex sm:max-w-[200px]'}>
              <div className={'flex flex-col justify-center'}>
                <Typography.Text className={'text-dark-liver font-medium sm:max-w-[200px]'} ellipsis={true} data-id={user.id}>
                  <span className={'hidden sm:flex'}>{user.fullName}</span>
                  <span className={'flex sm:hidden'}>{user.fullName.charAt(0)}.</span>
                </Typography.Text>
                {user?.customerName && (
                  <Typography.Text className={'text-dark-liver text-xs hidden sm:flex'} ellipsis={true}>
                    {user.customerName}
                  </Typography.Text>
                )}
              </div>

              <FontAwesomeIcon icon={solid('caret-down')} />
            </div>
          </div>
        </ProfileDropdownMenu>
      )}

      <LanguageSwitcher />
    </div>
  );
};
