import {Base} from '../interfaces/base';
import {UnsubscribeFacility} from '../interfaces/unsubscribe-facility';
import {BaseAbstractModel} from './base.abstract';

export class UnsubscribeFacilityModel extends BaseAbstractModel<UnsubscribeFacilityModel> implements UnsubscribeFacility {
  public readonly storableProperties: (keyof UnsubscribeFacilityModel)[] = [
    'orderId',
    'pickupAddress',
    'readyForPickupFrom',
    'pickedUpAtTheLatest',
    'amountOfCabins',
    'toiletNo',
    'constructionSiteNo',
    'filledOutByName',
    'filledOutById',
    'customerName',
    'customerId',
    'mobile',
    'email',
    'note',
    'createdAt',
  ];
  public orderId: UnsubscribeFacility['orderId'];
  public pickupAddress: UnsubscribeFacility['pickupAddress'];
  public readyForPickupFrom: UnsubscribeFacility['readyForPickupFrom'];
  public pickedUpAtTheLatest: UnsubscribeFacility['pickedUpAtTheLatest'];
  public amountOfCabins: UnsubscribeFacility['amountOfCabins'];
  public toiletNo: UnsubscribeFacility['toiletNo'];
  public constructionSiteNo: UnsubscribeFacility['constructionSiteNo'];
  public filledOutByName: UnsubscribeFacility['filledOutByName'];
  public filledOutById: UnsubscribeFacility['filledOutById'];
  public customerName: UnsubscribeFacility['customerName'];
  public customerId: UnsubscribeFacility['customerId'];
  public mobile: UnsubscribeFacility['mobile'];
  public email: UnsubscribeFacility['email'];
  public note: UnsubscribeFacility['note'];
  public createdAt: UnsubscribeFacility['createdAt'];

  public constructor(props: Partial<Base> & UnsubscribeFacility) {
    super(props);

    this.orderId = props.orderId;
    this.pickupAddress = props.pickupAddress;
    this.readyForPickupFrom = props.readyForPickupFrom;
    this.pickedUpAtTheLatest = props.pickedUpAtTheLatest;
    this.amountOfCabins = props.amountOfCabins;
    this.toiletNo = props.toiletNo;
    this.constructionSiteNo = props.constructionSiteNo;
    this.filledOutByName = props.filledOutByName;
    this.filledOutById = props.filledOutById;
    this.customerName = props.customerName;
    this.customerId = props.customerId;
    this.mobile = props.mobile;
    this.email = props.email;
    this.note = props.note;

    this.createdAt = props.createdAt;
  }
}
