import {Locale} from '../i18n/locale';

export const LANGUAGE_ROUTE_PATTERN = ':lang';
export const CUSTOMER_ID_ROUTE_PATTERN = ':customerId';
export const ORDER_ID_ROUTE_PATTERN = ':orderId';
export const USER_ID_ROUTE_PATTERN = ':userId';

interface RouteLocaleProps {
  lang?: Locale | ':lang';
}

interface RouteCustomerIdProps {
  customerId?: string | ':customerId';
}

interface RouteOrderIdProps {
  orderId?: string | ':orderId';
}

export const AppRoutes = {
  auth: {
    index: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/auth`,
    login: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/auth/login`,
  },
  admin: {
    index: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/admin`,
  },
  customers: {
    index: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/customers`,
  },
  users: {
    index: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/users`,
  },
  user: {
    me: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/users/me`,
    changePassword: ({lang = LANGUAGE_ROUTE_PATTERN}: RouteLocaleProps = {}) => `/${lang}/users/me/change-password`,
  },
  orders: {
    list: ({lang = LANGUAGE_ROUTE_PATTERN, customerId = CUSTOMER_ID_ROUTE_PATTERN}: RouteLocaleProps & RouteCustomerIdProps = {}) =>
      `/${lang}/customers/${customerId}/orders`,
    detail: ({
      lang = LANGUAGE_ROUTE_PATTERN,
      customerId = CUSTOMER_ID_ROUTE_PATTERN,
      orderId = ORDER_ID_ROUTE_PATTERN,
    }: RouteLocaleProps & RouteCustomerIdProps & RouteOrderIdProps) => `/${lang}/customers/${customerId}/orders/${orderId}`,
  },
};
