import {Button as AntdButton} from 'antd';
import {ButtonProps as AntdButtonProps} from 'antd/lib/button/button';
import React from 'react';

import {FC} from '../types/react';

interface ButtonProps extends AntdButtonProps {
  hasIcon?: boolean;
}

export const Button: FC<ButtonProps> = ({children, hasIcon, className, ...props}) => (
  <AntdButton
    ghost
    type={'primary'}
    className={hasIcon ? ['inline-flex items-center gap-1', className].filter((v) => v).join(' ') : className}
    {...props}
  >
    {children}
  </AntdButton>
);
