import {ObjectEntries} from '../interfaces/object-entries';
import {valueToString} from './value-to-string';

export const modelToString = <T>(v: T, props: (keyof T)[]): string =>
  (Object.entries(v) as ObjectEntries<T>[])
    .filter(([prop]) => !props.includes(prop))
    .map(([, propValue]) => valueToString(propValue).toLowerCase().trim())
    .filter((propValue) => propValue)
    .join(' ')
    .trim();
