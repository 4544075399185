export class FileUploadError extends Error {
  public files: string[] = [];

  public constructor(files: string[], message?: string) {
    super(message || 'Error on file upload');
    this.name = 'FileUploadError';
    this.files = files;
    Object.setPrototypeOf(this, FileUploadError.prototype);
  }
}
