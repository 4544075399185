export type SortOrder = 'asc' | 'desc';
export type AntdSortOrder = 'descend' | 'ascend' | null;

export type CompareFn<T> = (a: T, b: T, sortOrder?: SortOrder) => number;
export type AntdCompareFn<T> = (a: T, b: T, sortOrder?: AntdSortOrder) => number;

export const sortBoolean: CompareFn<boolean> = (a, b, sortOrder = 'asc') => (Number(a) - Number(b)) * (sortOrder === 'asc' ? -1 : 1);

export const sortNumerically: CompareFn<number> = (a, b, sortOrder = 'asc'): number => {
  return ((a || 0) - (b || 0)) * (sortOrder === 'asc' ? 1 : -1);
};

export const sortChronologically: CompareFn<string | Date> = (a, b, sortOrder = 'asc'): number =>
  (new Date(a).getTime() - new Date(b).getTime()) * (sortOrder === 'asc' ? 1 : -1);

export const sortAlphabetical: CompareFn<string | undefined> = (a, b, sortOrder = 'asc') =>
  (a || '').localeCompare(b || '') * (sortOrder === 'asc' ? 1 : -1);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sorter: <T = Record<string | number, unknown>, CF extends CompareFn<any> = CompareFn<any>>(
  field: keyof T,
  compareFn: CF,
) => AntdCompareFn<T> = (field, compareFn) => (a, b) => compareFn(a[field], b[field]);

export const AntdNumericalSortDirections: AntdSortOrder[] = ['descend', 'ascend'];
