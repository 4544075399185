import React, {FC, PropsWithChildren} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {useAuth} from '../hooks/useAuth';
import {useLang} from '../hooks/useLang';
import {AppRoutes} from '../utils/route.helper';
import {FullScreenLoader} from './FullScreenLoader';

export const AuthGuard: FC<PropsWithChildren> = ({children}) => {
  const {loading, isLoggedIn, firebaseUser, user} = useAuth();
  const location = useLocation();
  const lang = useLang();

  const loginRoute = AppRoutes.auth.login({lang});
  const customerDashboardRoute = AppRoutes.customers.index({lang});

  if (loading || (isLoggedIn && !firebaseUser && !user)) {
    return <FullScreenLoader />;
  }

  if (firebaseUser && user && location.pathname === loginRoute) {
    const comingFromRoute = (location?.state as {from?: Location})?.from?.pathname;
    const redirectAfterLoginRoute = comingFromRoute && !comingFromRoute.includes('/login') ? comingFromRoute : customerDashboardRoute;

    return <Navigate to={redirectAfterLoginRoute} replace />;
  }

  if (!firebaseUser && !user && ![loginRoute, 'auth/account-management'].some((route) => location.pathname.endsWith(route))) {
    return <Navigate to={loginRoute} replace state={{from: location}} />;
  }

  return <>{children}</>;
};
