import {Dropdown, Menu, Segmented, Space, Typography} from 'antd';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useLang} from '../hooks/useLang';
import {i18n} from '../i18n/i18n';
import {Locale} from '../i18n/locale';

interface LanguageChangerProp {
  design?: 'segmented' | 'dropdown';
}

export const LanguageSwitcher: FC<LanguageChangerProp> = ({design = 'dropdown'} = {}) => {
  const {t} = useTranslation();
  const lang = useLang();
  const [value, setValue] = useState<Locale>(lang);

  const onChange = (lang: Locale) => {
    setValue(lang);
    void i18n.changeLanguage(lang);
  };

  const menu = (
    <Menu
      selectable
      defaultSelectedKeys={[value]}
      items={Object.values(Locale).map((lang) => ({label: t(`lang.${lang}`), value: lang, key: lang}))}
      onClick={({key}) => onChange(key as Locale)}
    />
  );

  switch (design) {
    case 'segmented':
      return (
        <Segmented
          options={Object.values(Locale).map((lang) => ({label: lang.toUpperCase(), title: t(`lang.${lang}`), value: lang}))}
          value={value}
          onChange={(v) => onChange(v as Locale)}
        />
      );

    case 'dropdown':
      return (
        <Dropdown overlay={menu} placement={'bottomRight'}>
          <Typography.Link>
            <Space>{value.toUpperCase()}</Space>
          </Typography.Link>
        </Dropdown>
      );
  }
};
