import '@wc-vermietung/library/lib/date';

import {ConfigProvider} from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import React, {FC} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import {AuthGuard} from './components/AuthGuard';
import {AuthProvider} from './contexts/auth';
import {FirebaseProvider} from './contexts/firebase';
import {RepositoriesProvider} from './contexts/repositories';
import {StoreProvider} from './contexts/store';
import {useLang} from './hooks/useLang';
import {fixLanguagePathTemplate, i18n, updateLanguageInUrl} from './i18n/i18n';
import {AdminRoutes} from './pages/admin/AdminRoutes';
import {AuthRoutes} from './pages/auth/AuthRoutes';
import {CustomerRoutes} from './pages/customer/CustomerRoutes';
import {NotFound} from './pages/error/NotFound';
import {UserRoutes} from './pages/user/UserRoutes';
import {AppRoutes, LANGUAGE_ROUTE_PATTERN} from './utils/route.helper';

document.documentElement.lang = i18n.resolvedLanguage;
dayjs.locale(i18n.resolvedLanguage);

const getAntdLocale = () => {
  switch (i18n.resolvedLanguage) {
    case 'en':
      return enUS;

    case 'de':
    default:
      return deDE;
  }
};

updateLanguageInUrl(i18n.resolvedLanguage);
fixLanguagePathTemplate(i18n.resolvedLanguage);

export const App: FC = () => {
  const lang = useLang();

  return (
    <ConfigProvider locale={getAntdLocale()}>
      <FirebaseProvider>
        <RepositoriesProvider>
          <BrowserRouter>
            <AuthProvider>
              <AuthGuard>
                <StoreProvider>
                  <Routes>
                    <Route path={'*'} element={<NotFound />} />
                    <Route index element={<Navigate to={AppRoutes.auth.login({lang})} replace />} />

                    <Route path={`/${LANGUAGE_ROUTE_PATTERN}`}>
                      <Route path={'*'} element={<NotFound />} />
                      <Route index element={<Navigate to={AppRoutes.auth.login({lang})} replace />} />

                      <Route path={`${AppRoutes.auth.index()}/*`} element={<AuthRoutes />} />
                      <Route path={`${AppRoutes.admin.index()}/*`} element={<AdminRoutes />} />
                      <Route path={`${AppRoutes.customers.index()}/*`} element={<CustomerRoutes />} />
                      <Route path={`${AppRoutes.users.index()}/*`} element={<UserRoutes />} />
                    </Route>
                  </Routes>
                </StoreProvider>
              </AuthGuard>
            </AuthProvider>
          </BrowserRouter>
        </RepositoriesProvider>
      </FirebaseProvider>
    </ConfigProvider>
  );
};
