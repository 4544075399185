import {useEffect, useState} from 'react';

import {UserRole} from '@wc-vermietung/library/interfaces/user';
import {BaseAbstractModel} from '@wc-vermietung/library/models/base.abstract';
import {UserModel} from '@wc-vermietung/library/models/user';
import {BaseRepository} from '@wc-vermietung/library/repositories/client/base';

import {RepositoriesContextType} from '../contexts/repositories';
import {useRepository} from './useRepository';

export const useLoadOneCollection = <MODEL extends BaseAbstractModel<MODEL>>({
  repositoryName,
  id,
  user,
  requirements,
}: {
  repositoryName: keyof RepositoriesContextType;
  id: string | undefined;
  user?: UserModel | undefined;
  requirements?: {userRole?: UserRole[]};
}) => {
  const repository = useRepository(repositoryName);

  const [data, setData] = useState<MODEL>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (requirements && requirements.userRole && (!user || !requirements.userRole.includes(user.role))) {
      setIsLoading(false);
      return;
    }

    if (repository instanceof BaseRepository) {
      if (!id) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      repository
        .getOne(id)
        .then((entries) => setData(entries as unknown as MODEL))
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, user, requirements, repository, id]);

  const forceLoading = () => setIsLoading(true);

  return {data, isLoading, forceLoading};
};
