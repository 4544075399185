import {QueryDocumentSnapshot} from '@firebase/firestore';

import {Order, OrderDocument} from '../../interfaces/order';
import {OrderModel} from '../../models/order';
import {BaseRepository} from './base';

export const COLLECTION_PATH_ORDERS = 'orders';

export class OrderRepository extends BaseRepository<OrderDocument, OrderModel> {
  public constructor() {
    super({collectionPath: COLLECTION_PATH_ORDERS});
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<OrderDocument>): OrderModel {
    const snapshotData = snapshot.data();

    return new OrderModel({
      ...snapshotData,
      id: snapshot.id,

      shippedAt: snapshotData.shippedAt?.toDate(),
      rentFrom: snapshotData.rentFrom?.toDate(),
      rentTo: snapshotData.rentTo?.toDate() ?? null,

      createdAt: snapshotData.createdAt?.toDate(),
      updatedAt: snapshotData.updatedAt?.toDate(),

      // added 2022-12-16
      attachments: Object.values(snapshotData?.attachments || {})
        .map<Order['attachments'][0]>((v) => ({...v, date: v.date.toDate()}))
        .reduce<Order['attachments']>((prev, curr) => ({...prev, [curr.id]: curr}), {}),
    });
  }

  public markAsUnsubscribed(id: OrderModel['id']) {
    return this.update({id, isUnsubscribed: true});
  }
}
