import {Tag} from 'antd';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {OrderStatus} from '@wc-vermietung/library/interfaces/order';

import './OrderStatusTag.scss';

export const OrderStatusTag: FC<{orderStatus?: OrderStatus}> = (props) => {
  const {t} = useTranslation();

  const orderStatus = props.orderStatus && Object.values(OrderStatus).includes(props.orderStatus) ? props.orderStatus : 'unknown';

  return <Tag className={`order-status-tag ${orderStatus}`}>{t(`models.order.status_type.${orderStatus}`)}</Tag>;
};
