import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Descriptions} from 'antd';
import dayjs from 'dayjs';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {DATE_FORMAT} from '@wc-vermietung/library/lib/date';

import {Button} from '../../../components/Button';
import {EmDash} from '../../../components/EmDash';
import {DefaultLayout} from '../../../components/layouts/DefaultLayout';
import {useAuth} from '../../../hooks/useAuth';
import {useStore} from '../../../hooks/useStore';
import {NotFound} from '../../error/NotFound';

export const UserDetail: FC = () => {
  const {userId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {user} = useAuth();

  const {isUsersLoading, users} = useStore();

  const isUserPage = userId && (userId === 'me' || (user && userId === user.id));
  const currentUser = isUserPage ? user : users.find(({id}) => id === userId);
  const isUserDataLoading = !isUserPage && isUsersLoading;

  if (!isUserDataLoading && userId && !currentUser) {
    return <NotFound />;
  }

  return (
    <DefaultLayout
      pageTitle={isUserDataLoading ? t('users.detail.user_detail') : isUserPage ? t('users.detail.my_profile') : currentUser?.fullName}
      loading={isUserDataLoading}
    >
      <Descriptions size={'small'} className={'descriptions-vertical max-w-120px'}>
        <Descriptions.Item label={t('models.user.email')}>{currentUser?.email}</Descriptions.Item>
        <Descriptions.Item label={t('models.user.active')}>
          {currentUser
            && (currentUser.active ? (
              <FontAwesomeIcon icon={solid('circle-check')} className={'text-strong-lime-green'} />
            ) : (
              <FontAwesomeIcon icon={solid('circle-xmark')} className={'text-alizarin'} />
            ))}
        </Descriptions.Item>
        <Descriptions.Item>&nbsp;</Descriptions.Item>
        {user?.isAdmin && (
          <Descriptions.Item label={t('models.user.role')}>
            {currentUser?.role && t(`models.user.roles.${String(currentUser.role)}`)}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t('models.user.customer_id')}>
          {!isUserDataLoading && (currentUser?.customerName || EmDash)}
        </Descriptions.Item>
        <Descriptions.Item>&nbsp;</Descriptions.Item>
        <Descriptions.Item label={t('models.user.created_at')}>
          {currentUser?.createdAt && dayjs(currentUser.createdAt).format(DATE_FORMAT.DATE_AND_TIME)}
        </Descriptions.Item>
        <Descriptions.Item label={t('models.user.updated_at')}>
          {currentUser?.updatedAt && dayjs(currentUser.updatedAt).format(DATE_FORMAT.DATE_AND_TIME)}
        </Descriptions.Item>
      </Descriptions>

      {isUserPage && (
        <Button className={'font-medium px-7 mt-20'} onClick={() => navigate('change-password')}>
          {t('actions.change_password')}
        </Button>
      )}
    </DefaultLayout>
  );
};
