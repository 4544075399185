import {FirebaseError} from '@firebase/util';
import {Alert, Form, Image, Input, Layout, Typography} from 'antd';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import mondoFutura from '../../../assets/img/mondo-futura.png';
import {Button} from '../../../components/Button';
import {LanguageSwitcher} from '../../../components/LanguageSwitcher';
import {Footer} from '../../../components/layouts/Footer';
import {LogoImage} from '../../../components/LogoImage';
import {SendResetPasswordMailModalForm} from '../../../components/SendResetPasswordMailModalForm';
import {useAuth} from '../../../hooks/useAuth';
import {useDocumentTitle} from '../../../hooks/useDocumentTitle';
import {useRepository} from '../../../hooks/useRepository';
import {FirebaseAuthErrorCodes} from '../../../types/firebase';
import {getInvalidFieldNames} from '../../../utils/form.helper';
import {openMessage} from '../../../utils/message.helper';
import {REGEX} from '../../../utils/regex.helper';

interface LoginForm {
  email: string;
  password: string;
}

export const Login: FC = () => {
  const {t, i18n} = useTranslation();
  const {loginWithEmailAndPassword} = useAuth();
  const authRepository = useRepository('auth');

  useDocumentTitle(t('login.form.title'));
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [firebaseErrorCode, setFirebaseErrorCode] = useState<FirebaseAuthErrorCodes>();
  const [sendResetPasswordFormError, setSendResetPasswordFormError] = useState<FirebaseAuthErrorCodes>();
  const [showSendResetPasswordMailModal, setShowSendResetPasswordMailModal] = useState(false);
  const [sendResetPasswordFormEmail, setSendResetPasswordFormEmail] = useState<string>();

  const [form] = Form.useForm<LoginForm>();
  const {email} = Form.useWatch<LoginForm>([], form) || {};

  useEffect(() => {
    setSendResetPasswordFormEmail(email);
  }, [setSendResetPasswordFormEmail, email]);

  useEffect(() => {
    const invalidFields = getInvalidFieldNames(form);

    if (invalidFields.length) {
      void form.validateFields(invalidFields);
    }
  }, [form, i18n.resolvedLanguage]);

  const onLogin = (values: LoginForm) => {
    setLoginInProgress(true);

    loginWithEmailAndPassword(values).catch((error: unknown) => {
      if (error instanceof FirebaseError) {
        const firebaseErrorCode = error.code as FirebaseAuthErrorCodes;

        switch (firebaseErrorCode) {
          // t('firebase.errors.auth/user-not-found')
          // t('firebase.errors.auth/invalid-email')
          // t('firebase.errors.auth/user-disabled')
          case 'auth/user-not-found':
          case 'auth/invalid-email':
          case 'auth/user-disabled':
            form.setFields([{name: 'email', errors: [t(`firebase.errors.${error.code}`)]}]);
            break;

          // t('firebase.errors.auth/wrong-password')
          case 'auth/wrong-password':
            form.setFields([{name: 'password', errors: [t(`firebase.errors.${error.code}`)]}]);
            break;

          default:
            // t('firebase.errors.auth/network-request-failed')
            setFirebaseErrorCode(firebaseErrorCode);
        }
      }

      setLoginInProgress(false);
    });
  };

  const onLoginFailed = (errorInfo: ValidateErrorEntity<LoginForm>) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <Layout className={'min-h-screen bg-transparent'}>
      <Layout.Header className={'text-right px-8 bg-transparent'}>
        <LanguageSwitcher design={'segmented'} />
      </Layout.Header>

      <Layout.Content className={'flex flex-row justify-center'}>
        <div className={'fixed bottom-16 right-0 hidden lg:block'}>
          <Image src={mondoFutura} alt={'mondo futura'} preview={false} className={'w-2/5 h-auto float-right'} />
        </div>

        <div className={'flex flex-col mt-0 max-w-xs w-5/6'}>
          <LogoImage className={'mx-auto mb-16 sm:mt-[5vh] max-w-[200px] transition-all ease-in-out delay-150'} noLink />

          <div>
            <Typography.Text className={'font-medium'}>{t('login.form.title')}</Typography.Text>
            <div className={'login-form-title-hr w-1/4 mt-2 mb-4 border-b-2 border-black'}></div>
            <Form name={'login'} form={form} onFinish={onLogin} onFinishFailed={onLoginFailed} layout={'vertical'} requiredMark={false}>
              <Form.Item
                label={t('models.user.email')}
                name={'email'}
                rules={[
                  {required: true, message: t('form.errors.email_missing')},
                  {
                    pattern: REGEX.email,
                    message: t('form.errors.invalid_email'),
                  },
                ]}
              >
                <Input autoComplete={'username'} />
              </Form.Item>

              <Form.Item
                label={
                  <>
                    {t('models.user.password')}
                    <>
                      &nbsp;(
                      <Typography.Link onClick={() => setShowSendResetPasswordMailModal(true)}>
                        {t('actions.forgot_password')}?
                      </Typography.Link>
                      )
                    </>
                  </>
                }
                name={'password'}
                rules={[{required: true, message: t('form.errors.password_missing')}]}
              >
                <Input.Password autoComplete={'current-password'} />
              </Form.Item>

              {firebaseErrorCode && (
                <Alert
                  className={'mb-4'}
                  message={t(`firebase.errors.${firebaseErrorCode}`)}
                  type={'error'}
                  onClose={() => setFirebaseErrorCode(undefined)}
                  showIcon
                  closable
                />
              )}

              <Form.Item className={'my-16'}>
                <Button htmlType={'submit'} className={'font-medium px-7'} loading={loginInProgress}>
                  {t('login.form.submit')}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Text>{t('register.title')}</Typography.Text>
            <br />
            <Typography.Link href={'https://www.wcvermietung.ch/kontakt/'} target={'_blank'} rel={'noopener noreferrer'} className={'mt-2'}>
              {t('register.instruction')}
            </Typography.Link>
          </div>
        </div>
        <SendResetPasswordMailModalForm
          visible={showSendResetPasswordMailModal}
          email={sendResetPasswordFormEmail}
          formError={sendResetPasswordFormError}
          onCreate={({email}) => {
            setSendResetPasswordFormEmail(email);

            authRepository
              .sendPasswordResetEmail(email)
              .then(() => {
                void openMessage({
                  type: 'success',
                  content: t('message.reset_password_mail_sent'),
                  key: 'reset-password-mail',
                });
                setShowSendResetPasswordMailModal(false);
              })
              .catch((error) => {
                if (error instanceof FirebaseError) {
                  setSendResetPasswordFormError(error.code as FirebaseAuthErrorCodes);
                }
              });
          }}
          onCancel={() => {
            setShowSendResetPasswordMailModal(false);
            setSendResetPasswordFormError(undefined);
            setSendResetPasswordFormEmail(email);
          }}
        />
      </Layout.Content>

      <Footer />
    </Layout>
  );
};
