import {QueryConstraint} from '@firebase/firestore';
import {useEffect, useState} from 'react';

import {UserRole} from '@wc-vermietung/library/interfaces/user';
import {BaseAbstractModel} from '@wc-vermietung/library/models/base.abstract';
import {UserModel} from '@wc-vermietung/library/models/user';
import {BaseRepository} from '@wc-vermietung/library/repositories/client/base';

import {RepositoriesContextType} from '../contexts/repositories';
import {useRepository} from './useRepository';

export const useLoadAllCollection = <MODEL extends BaseAbstractModel<MODEL>>({
  repositoryName,
  user,
  requirements,
  queryConstraints,
}: {
  repositoryName: keyof RepositoriesContextType;
  user?: UserModel | undefined;
  requirements?: {userRole?: UserRole[]; isLoggedIn?: boolean};
  queryConstraints?: QueryConstraint[];
}) => {
  const repository = useRepository(repositoryName);

  const [data, setData] = useState<MODEL[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (
      requirements
      && ((requirements.userRole && (!user || !requirements.userRole.includes(user.role))) || (requirements.isLoggedIn && !user))
    ) {
      setIsLoading(false);
      return;
    }

    if (repository instanceof BaseRepository) {
      repository
        .getAll(queryConstraints)
        .then((entries) => setData(entries as unknown as MODEL[]))
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, user, requirements, repository, queryConstraints]);

  const forceLoading = () => setIsLoading(true);

  return {data, isLoading, forceLoading};
};
