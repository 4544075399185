import {Dropdown, Menu, MenuProps, Typography} from 'antd';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../hooks/useAuth';
import {useLang} from '../hooks/useLang';
import {AppRoutes} from '../utils/route.helper';

export const ProfileDropdownMenu: FC<PropsWithChildren> = ({children}) => {
  const {logout} = useAuth();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const lang = useLang();

  const profileMenuHandler: MenuProps['onClick'] = ({key}) => {
    switch (key) {
      case 'profile-edit':
        navigate(AppRoutes.user.me({lang}));
        break;

      case 'logout':
        void logout();
        break;
    }
  };

  const menu = (
    <Menu
      onClick={profileMenuHandler}
      items={[
        {key: 'profile-edit', label: <>{t('users.detail.my_profile')}</>},
        {key: 'logout', label: <>{t('actions.logout')}</>},
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} placement={'bottomRight'}>
      <Typography.Link>{children}</Typography.Link>
    </Dropdown>
  );
};
