import {Form, Input, Modal, Typography} from 'antd';
import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {FirebaseAuthErrorCodes} from '../types/firebase';
import {REGEX} from '../utils/regex.helper';

interface SentResetPasswordMailForm {
  email: string;
}

interface SendResetPasswordMailModalFormProps {
  visible: boolean;
  email?: string;
  formError?: FirebaseAuthErrorCodes;
  onCreate: (values: SentResetPasswordMailForm) => void;
  onCancel: () => void;
}

export const SendResetPasswordMailModalForm: FC<SendResetPasswordMailModalFormProps> = ({
  visible,
  email,
  formError,
  onCreate,
  onCancel,
}) => {
  const {t} = useTranslation();

  const [form] = Form.useForm<SentResetPasswordMailForm>();

  useEffect(() => {
    form.setFieldsValue({email});
  });

  if (formError) {
    form.setFields([{name: 'email', errors: [t(`firebase.errors.${formError}`)]}]);
  }

  return (
    <Modal
      forceRender
      visible={visible}
      title={t('modal.send_reset_password_mail.title')}
      okText={t('actions.reset_password')}
      cancelText={t('actions.cancel')}
      onOk={() =>
        void form.validateFields().then((v) => {
          onCreate(v);
        })
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
    >
      <Typography.Paragraph>{t('modal.send_reset_password_mail.explanation')}:</Typography.Paragraph>

      <Form form={form} layout={'vertical'} requiredMark={false}>
        <Form.Item
          label={t('models.user.email')}
          name={'email'}
          rules={[
            {required: true, message: t('form.errors.email_missing')},
            {
              pattern: REGEX.email,
              message: t('form.errors.invalid_email'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
