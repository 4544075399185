import dayjs from 'dayjs';

import {DATE_FORMAT} from '../lib/date';
import {objectToString} from './object-to-string';

export const valueToString = <T>(propValue: T): string => {
  if (propValue instanceof Date) {
    return dayjs(propValue).format(DATE_FORMAT.DATE_AND_TIME);
  }

  switch (typeof propValue) {
    case 'boolean':
    case 'undefined':
      return '';

    case 'object':
      return Array.isArray(propValue) ? propValue.join(' ').trim() : objectToString(propValue);

    default:
      return String(propValue);
  }
};
