import React, {createContext, FC, PropsWithChildren} from 'react';

import {CustomerStatistics} from '@wc-vermietung/library/interfaces/statistic/customer-statistic';
import {AuthRepository} from '@wc-vermietung/library/repositories/client/auth';
import {CustomerRepository} from '@wc-vermietung/library/repositories/client/customer';
import {OrderRepository} from '@wc-vermietung/library/repositories/client/order';
import {StatisticRepository} from '@wc-vermietung/library/repositories/client/statistic';
import {UserRepository} from '@wc-vermietung/library/repositories/client/user';

export interface RepositoriesContextType {
  auth: AuthRepository;
  user: UserRepository;
  customer: CustomerRepository;
  order: OrderRepository;
  customerStatistic: StatisticRepository<CustomerStatistics>;
}

const defaultValue: RepositoriesContextType = {
  auth: new AuthRepository(),
  user: new UserRepository(),
  customer: new CustomerRepository(),
  order: new OrderRepository(),
  customerStatistic: new StatisticRepository<CustomerStatistics>(),
};

export const RepositoriesContext = createContext<RepositoriesContextType>(defaultValue);

export const RepositoriesProvider: FC<PropsWithChildren> = ({children}) => (
  <RepositoriesContext.Provider value={defaultValue}>{children}</RepositoriesContext.Provider>
);
