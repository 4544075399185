import {regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Result} from 'antd';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../components/Button';

export const Forbidden: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Result
      status={'403'}
      title={'403'}
      subTitle={t('error.403.info_message')}
      extra={
        <Button type={'primary'} hasIcon onClick={() => navigate('/')} ghost={false}>
          <FontAwesomeIcon icon={regular('arrow-left')} />
          {t('error.general.go_to_home')}
        </Button>
      }
    />
  );
};
