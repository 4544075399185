import {
  confirmPasswordReset,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
  User as FirebaseAuthUser,
  verifyPasswordResetCode,
} from 'firebase/auth';

export class AuthRepository {
  /**
   * t('firebase.errors.auth/internal-error')
   */
  public updatePassword({
    user,
    currentPassword,
    newPassword,
  }: {
    user: FirebaseAuthUser;
    currentPassword: string;
    newPassword: string;
  }): Promise<void> {
    return reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email as string, currentPassword)).then(() =>
      updatePassword(user, newPassword),
    );
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(getAuth(), email);
  }

  public verifyPasswordResetCode({oobCode}: {oobCode: string}): Promise<string> {
    return verifyPasswordResetCode(getAuth(), oobCode);
  }

  public confirmPasswordReset({oobCode, newPassword}: {oobCode: string; newPassword: string}) {
    return confirmPasswordReset(getAuth(), oobCode, newPassword);
  }
}
