import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  getFirestore,
  PartialWithFieldValue,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
  UpdateData,
  updateDoc,
} from '@firebase/firestore';

import {BaseAbstractModel} from '../../models/base.abstract';

export abstract class BaseRepository<DOC extends DocumentData, MODEL extends BaseAbstractModel<MODEL>> {
  protected collectionPath: string;

  protected constructor({collectionPath}: {collectionPath: string}) {
    this.collectionPath = collectionPath;
  }

  public get collection(): CollectionReference<MODEL> {
    return collection(getFirestore(), this.collectionPath).withConverter(this.converter());
  }

  public toFirestore(modelObject: PartialWithFieldValue<MODEL>): DOC {
    if (
      modelObject
      && 'storableProperties' in modelObject
      && Array.isArray(modelObject.storableProperties)
      && modelObject.storableProperties.length
    ) {
      return (modelObject.storableProperties as MODEL['storableProperties']).reduce(
        (pre, prop) =>
          modelObject && prop in modelObject && Object.hasOwn(modelObject, prop) && (modelObject as MODEL)[prop] !== undefined
            ? {...pre, [prop]: (modelObject as MODEL)[prop]}
            : pre,
        {},
      ) as DOC;
    }

    return modelObject as DOC;
  }

  public abstract fromFirestore(_snapshot: QueryDocumentSnapshot<DOC>, _options?: SnapshotOptions): MODEL;

  public converter(): FirestoreDataConverter<MODEL> {
    return {
      toFirestore: this.toFirestore.bind(this),
      fromFirestore: this.fromFirestore.bind(this),
    };
  }

  public getAll(queryConstraints: QueryConstraint[] = []): Promise<MODEL[]> {
    return getDocs(query(this.collection, ...queryConstraints)).then((querySnapshot) =>
      querySnapshot.docs.map((snapshot) => snapshot.data()),
    );
  }

  public getOne(id: string): Promise<MODEL | undefined> {
    return getDoc(this.document(id)).then((snapshot) => snapshot.data());
  }

  public create(model: MODEL): Promise<MODEL> {
    return addDoc(this.collection, model).then((reference) => {
      model.id = reference.id;

      return model;
    });
  }

  public createWithId(id: string, model: MODEL): Promise<MODEL> {
    return setDoc(this.document(id), model).then(() => {
      model.id = id;

      return model;
    });
  }

  public update(model: Required<Pick<MODEL, 'id'>> & Partial<MODEL>): Promise<void> {
    return updateDoc(this.document(model.id), model as UpdateData<MODEL>);
  }

  public delete(id: string): Promise<void> {
    return deleteDoc(this.document(id));
  }

  private document(id: string): DocumentReference<MODEL> {
    return doc(getFirestore(), this.collectionPath, id).withConverter(this.converter());
  }
}
