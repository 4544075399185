import {getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, User as FirebaseAuthUser, UserCredential} from '@firebase/auth';
import React, {createContext, FC, PropsWithChildren, useEffect, useState} from 'react';

import {UserModel} from '@wc-vermietung/library/models/user';

import {useFirebase} from '../hooks/useFirebase';
import {useRepository} from '../hooks/useRepository';

export interface AuthContextType {
  loading: boolean;
  isLoggedIn: boolean;

  firebaseUser?: FirebaseAuthUser;
  user?: UserModel;

  loginWithEmailAndPassword: (props: {email: string; password: string}) => Promise<UserCredential>;
  logout: () => Promise<void>;
}

const loginWithEmailAndPassword: AuthContextType['loginWithEmailAndPassword'] = ({email, password}) =>
  signInWithEmailAndPassword(getAuth(), email, password);
const logout: AuthContextType['logout'] = () => signOut(getAuth());

const defaultSession: AuthContextType = {loading: true, isLoggedIn: false, loginWithEmailAndPassword, logout};

export const AuthContext = createContext<AuthContextType>(defaultSession);

export const AuthProvider: FC<PropsWithChildren> = ({children}) => {
  const {initialized} = useFirebase();
  const userRepository = useRepository('user');
  const [loading, setLoading] = useState(defaultSession.loading);
  const [isLoggedIn, setIsLoggedIn] = useState(defaultSession.isLoggedIn);
  const [firebaseUser, setFirebaseUser] = useState<AuthContextType['firebaseUser']>();
  const [user, setUser] = useState<AuthContextType['user']>();

  useEffect(() => {
    if (initialized) {
      onAuthStateChanged(getAuth(), (firebaseAuthUser) => {
        if (firebaseAuthUser?.uid) {
          void userRepository.getOne(firebaseAuthUser.uid).then((firestoreUser) => {
            if (!firestoreUser) {
              console.error(`Firestore user not found with uid="${firebaseAuthUser.uid}"`);
            }

            setFirebaseUser(firebaseAuthUser);
            setUser(firestoreUser);
            setIsLoggedIn(!!firestoreUser);
            setLoading(false);
          });

          return;
        }

        setFirebaseUser(undefined);
        setUser(undefined);
        setIsLoggedIn(false);
        setLoading(false);
      });
    }
  }, [initialized, userRepository]);

  return (
    <AuthContext.Provider value={{loading, isLoggedIn, user, firebaseUser, loginWithEmailAndPassword, logout}}>
      {children}
    </AuthContext.Provider>
  );
};
