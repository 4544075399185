import {Typography} from 'antd';
import React, {FC, PropsWithChildren} from 'react';

import {useDocumentTitle} from '../hooks/useDocumentTitle';

export const PageTitle: FC<PropsWithChildren> = ({children}) => {
  useDocumentTitle(typeof children === 'string' ? children : undefined);

  return (
    <Typography.Title level={3} className={'text-dark-liver mt-4 mb-16'}>
      {children}
    </Typography.Title>
  );
};
