import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space, Table} from 'antd';
import {snakeCase} from 'change-case';
import dayjs from 'dayjs';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {sortAlphabetical, sortBoolean, sortChronologically, sorter} from '@wc-vermietung/library/helpers/sorters';
import {Base} from '@wc-vermietung/library/interfaces/base';
import {User, UserRole} from '@wc-vermietung/library/interfaces/user';
import {DATE_FORMAT} from '@wc-vermietung/library/lib/date';
import {UserModel} from '@wc-vermietung/library/models/user';

import {DefaultLayout} from '../../../components/layouts/DefaultLayout';
import {SearchInput} from '../../../components/SearchInput';
import {useAuth} from '../../../hooks/useAuth';
import {useStore} from '../../../hooks/useStore';
import {generateTableColumns, getDataIndexList, TableColumn, TableRow} from '../../../utils/table.helper';

type UserListTableData = Base & User;
type UserListTableRow = TableRow<UserListTableData>;
type UserListTableColumn = TableColumn<UserListTableData>;

export const UserList: FC = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {isUsersLoading, users} = useStore();

  const [searchValue, setSearchValue] = useState<string>();

  const fields: UserListTableColumn[] = [
    {
      dataIndex: 'firstName',
      column: {
        defaultSortOrder: 'ascend',
        sorter: sorter<UserListTableRow>('firstName', sortAlphabetical),
      },
    },
    {
      dataIndex: 'lastName',
      column: {
        sorter: sorter<UserListTableRow>('lastName', sortAlphabetical),
      },
    },
    {
      dataIndex: 'email',
      column: {
        sorter: sorter<UserListTableRow>('email', sortAlphabetical),
      },
    },
    {
      dataIndex: 'customerName',
      column: {
        sorter: sorter<UserListTableRow>('customerName', sortAlphabetical),
      },
    },
    {
      dataIndex: 'role',
      column: {
        render: (value: UserRole) => t(`models.user.roles.${value}`),
        sorter: (a, b) => a.role.localeCompare(b.role),
        filterSearch: true,
        filters: Object.values(UserRole).map((role) => ({text: t(`models.user.roles.${snakeCase(role)}`), value: role})),
        onFilter: (value: string | number | boolean, record: UserListTableRow) => record.role === value || false,
      },
    },
    {
      dataIndex: 'active',
      column: {
        render: (value: boolean) =>
          value ? (
            <FontAwesomeIcon icon={solid('circle-check')} className={'text-strong-lime-green'} />
          ) : (
            <FontAwesomeIcon icon={solid('circle-xmark')} className={'text-alizarin'} />
          ),
        align: 'center',
        sorter: sorter<UserListTableRow>('active', sortBoolean),
        filterSearch: false,
        filterMultiple: false,
        filters: [
          {key: 'yes', value: true},
          {key: 'no', value: false},
        ].map(({key, value}) => ({text: t(`actions.${snakeCase(key)}`), value})),
        onFilter: (value: string | number | boolean, record: UserListTableRow) => record.active === value || false,
      },
    },
    {
      dataIndex: 'createdAt',
      column: {
        render: (value: Date) => dayjs(value).format(DATE_FORMAT.DATE_AND_TIME),
        sorter: sorter<UserListTableRow>('createdAt', sortChronologically),
      },
    },
    {
      dataIndex: 'updatedAt',
      column: {
        render: (value: Date) => dayjs(value).format(DATE_FORMAT.DATE_AND_TIME),
        sorter: sorter<UserListTableRow>('updatedAt', sortChronologically),
      },
    },
  ];

  const columns = generateTableColumns({fields, transPrefix: 'models.user'});
  const dataIndexList = getDataIndexList(fields);
  const dataSource: UserListTableRow[] = users
    .filter((user) => (searchValue ? user.hasValue(searchValue) : true))
    .sort(sorter<UserModel>('fullName', sortAlphabetical))
    .map((model) =>
      dataIndexList.reduce<UserListTableRow>((curr, dataIndex) => ({...curr, [dataIndex]: model[dataIndex]}), {
        key: model.id,
      } as UserListTableRow),
    );

  return (
    <DefaultLayout pageTitle={t('users.list.title')}>
      <Space className={'flex'} direction={'vertical'} size={40}>
        <SearchInput onChange={(event) => setSearchValue(event.target.value)} isLoading={isUsersLoading} />
        <Table
          className={'users'}
          rowClassName={'cursor-pointer'}
          size={'small'}
          columns={columns}
          dataSource={dataSource}
          scroll={{x: 'max-content'}}
          onRow={(record: UserListTableRow) => ({
            onClick: () => navigate(String(record.key === user?.id ? 'me' : record.key)),
          })}
          loading={isUsersLoading}
          pagination={{
            showTotal: (total, range) => t('pagination.of_items', {start: range[0], end: range[1], total}),
          }}
        />
      </Space>
    </DefaultLayout>
  );
};
