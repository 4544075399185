import {CreatedAt} from './created-at';
import {CreatedAtFirestore} from './created-at-firestore';
import {UpdatedAt} from './updated-at';
import {UpdatedAtFirestore} from './updated-at-firestore';

/**
 * t('models.user.roles.superadmin')
 * t('models.user.roles.admin')
 * t('models.user.roles.supervisor')
 * t('models.user.roles.user')
 */
export enum UserRole {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  SUPERVISOR = 'supervisor',
  USER = 'user',
}

/**
 * t(`models.user.created_at`)
 * t(`models.user.updated_at`)
 */
export interface User extends UserPartialDocument, CreatedAt, UpdatedAt {}

export interface UserDocument extends UserPartialDocument, CreatedAtFirestore, UpdatedAtFirestore {}

/**
 * t(`models.user.customer_id`)
 *
 * t(`models.user.first_name`)
 * t(`models.user.last_name`)
 * t(`models.user.email`)
 * t(`models.user.role`)
 * t(`models.user.active`)
 * t(`models.user.customer_name`)
 */
interface UserPartialDocument {
  customerId: string;

  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  active: boolean;
  customerName: string;
}
