import {getFullName} from '../helpers/user';
import {Base} from '../interfaces/base';
import {User, UserRole} from '../interfaces/user';
import {BaseAbstractModel} from './base.abstract';

export class UserModel extends BaseAbstractModel<UserModel> implements User {
  public readonly storableProperties: (keyof UserModel)[] = [
    'customerId',
    'firstName',
    'lastName',
    'email',
    'role',
    'active',
    'customerName',
    'createdAt',
    'updatedAt',
  ];
  public readonly searchableProperties: (keyof UserModel)[] = [
    'id',
    'customerId',
    'firstName',
    'lastName',
    'email',
    'customerName',
    'createdAt',
    'updatedAt',
  ];
  public customerId: User['customerId'];
  public firstName: User['firstName'];
  public lastName: User['lastName'];
  public email: User['email'];
  public role: User['role'];
  public active: User['active'];
  public customerName: User['customerName'];
  public createdAt: User['createdAt'];
  public updatedAt: User['updatedAt'];

  public constructor(props: Partial<Base> & User) {
    super(props);

    this.customerId = props.customerId;

    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.email = props.email;
    this.role = props.role;
    this.active = props.active;
    this.customerName = props.customerName;

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }

  public get fullName(): string {
    return getFullName({firstName: this.firstName, lastName: this.lastName});
  }

  public get isSuperAdmin(): boolean {
    return this.role === UserRole.SUPERADMIN;
  }

  public get isAdmin(): boolean {
    return [UserRole.SUPERADMIN, UserRole.ADMIN].includes(this.role);
  }

  public get isSupervisor(): boolean {
    return this.role === UserRole.SUPERVISOR;
  }

  public get isUser(): boolean {
    return this.role === UserRole.USER;
  }
}
