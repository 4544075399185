import {useEffect, useState} from 'react';

import {Config} from '../config';

export const useDocumentTitle = (title?: string) => {
  const [documentTitle, setDocumentTitle] = useState(title);

  useEffect(() => {
    if (title !== undefined && title !== null) {
      document.title = [title ? `${title} |` : '', Config.documentTitle].join(' ');
    }
  }, [title]);

  return [documentTitle, setDocumentTitle];
};
