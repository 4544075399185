import {Base} from '../interfaces/base';
import {Statistic} from '../interfaces/statistic';
import {CustomerStatistics} from '../interfaces/statistic/customer-statistic';
import {BaseAbstractModel} from './base.abstract';

export class StatisticModel<STATS> extends BaseAbstractModel<StatisticModel<STATS>> implements Statistic<STATS> {
  public readonly storableProperties: (keyof StatisticModel<STATS>)[] = ['data', 'createdAt', 'updatedAt'];
  public data: Statistic<STATS>['data'];
  public createdAt: Statistic<STATS>['createdAt'];
  public updatedAt: Statistic<STATS>['updatedAt'];

  public constructor(props: Partial<Base> & Statistic<STATS>) {
    super(props);

    this.data = props.data;

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}

export type CustomerStatisticModel = StatisticModel<CustomerStatistics>;
