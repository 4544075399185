import {getAuth} from '@firebase/auth';
import dayjs from 'dayjs';
import i18next, {InitOptions} from 'i18next';
import LanguageDetector, {DetectorOptions} from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import {Config} from '../config';
import {LANGUAGE_ROUTE_PATTERN} from '../utils/route.helper';
import {Locale} from './locale';
import de from './locales/de.json';
import en from './locales/en.json';

const options: InitOptions & DetectorOptions = {
  resources: {
    [Locale.DE]: {translation: de},
    [Locale.EN]: {translation: en},
  },
  detection: {
    order: ['querystring', 'path', 'navigator'],
    lookupQuerystring: 'lang',
  },
  load: 'all',
  lowerCaseLng: true,
  fallbackLng: Config.i18n.fallbackLng,
  debug: process.env.NODE_ENV !== 'production',
  supportedLngs: Object.values(Locale),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  nsSeparator: '-::-',
  returnObjects: true,
};

void i18next.use(initReactI18next).use(LanguageDetector).init(options);

export const updateLanguageInUrl = (lang: string) => {
  const {pathname, search} = window.location;

  // @see: https://regex101.com/r/VRHscf/1
  window.history.replaceState(null, '', pathname.replace(/^(\/?de|\/?en)?(.*)$/m, `/${lang}$2`) + search);
};

export const fixLanguagePathTemplate = (lang: string) => {
  const {pathname, search} = window.location;

  window.history.replaceState(null, '', pathname.replace(LANGUAGE_ROUTE_PATTERN, lang) + search);
};

i18next.on('languageChanged', (lang) => {
  dayjs.locale(lang);

  getAuth().languageCode = lang;

  updateLanguageInUrl(lang);
});

export const i18n = i18next;
