import React, {FC, PropsWithChildren} from 'react';
import {useParams} from 'react-router-dom';

import {UserRole} from '@wc-vermietung/library/interfaces/user';

import {useAuth} from '../hooks/useAuth';
import {Forbidden} from '../pages/error/Forbidden';

type PermissionGuardAddChecker<T> = {[P in keyof T & string as `is${Capitalize<P>}`]: T[P]};
type PermissionGuardType = Partial<PermissionGuardAddChecker<Record<UserRole, boolean>>>;

export const PermissionGuard: FC<PropsWithChildren<PermissionGuardType>> = ({children, ...props}) => {
  const {user} = useAuth();
  const {customerId, userId} = useParams();

  if (props.isAdmin && !user?.isAdmin) {
    return <Forbidden />;
  }

  if (props.isSuperadmin && !user?.isSuperAdmin) {
    return <Forbidden />;
  }

  if (customerId && !user?.isAdmin && user?.customerId !== customerId) {
    return <Forbidden />;
  }

  if (userId && userId !== 'me' && !user?.isAdmin && user?.id !== userId) {
    return <Forbidden />;
  }

  return <>{children}</>;
};
