import {Col, DatePicker, Radio, Row, Typography} from 'antd';
import moment, {Moment} from 'moment';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {DATE_FORMAT} from '@wc-vermietung/library/lib/date';

import classes from './DesiredDeliveryDateSelector.module.scss';

interface DesiredDeliveryDateSelectorProps {
  onChange?: (value: Moment | null) => unknown;
  value?: Moment | null;
  mode: DesiredDeliveryDateMode;
}

export enum DesiredDeliveryDateMode {
  SOON_AS_POSSIBLE = 'soon-as-possible',
  DATE = 'date',
}

export const DesiredDeliveryDateSelector: FC<DesiredDeliveryDateSelectorProps> = ({onChange, mode: initialMode, value}) => {
  const {t} = useTranslation();
  const [mode, setMode] = useState<DesiredDeliveryDateMode | undefined>(
    initialMode ?? (value ? DesiredDeliveryDateMode.DATE : DesiredDeliveryDateMode.SOON_AS_POSSIBLE),
  );

  const handleAsSoonAsPossible = () => {
    setMode(DesiredDeliveryDateMode.SOON_AS_POSSIBLE);
    onChange?.(moment());
  };

  const handleDateSelection = () => {
    setMode(DesiredDeliveryDateMode.DATE);
    onChange?.(null);
  };

  const handleDateChange = (date: Moment | null) => {
    onChange?.(date);
  };

  useEffect(() => {
    switch (mode) {
      case DesiredDeliveryDateMode.SOON_AS_POSSIBLE:
        handleAsSoonAsPossible();
        break;
      case DesiredDeliveryDateMode.DATE:
        handleDateSelection();
        break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col className={'flex align-items-center'} xs={10}>
        <Radio onChange={handleAsSoonAsPossible} checked={mode === DesiredDeliveryDateMode.SOON_AS_POSSIBLE}>
          <Typography.Text>{t('view.new_order_modal.form.as_soon_as_possible')}</Typography.Text>
        </Radio>
      </Col>
      <Col xs={14}>
        <Radio className={classes.radioItem__dateRadio} onChange={handleDateSelection} checked={mode === DesiredDeliveryDateMode.DATE}>
          <DatePicker
            onChange={handleDateChange}
            disabled={mode === DesiredDeliveryDateMode.SOON_AS_POSSIBLE}
            disabledDate={(date) => moment(date.toDate()).isBefore(moment(), 'day')}
            format={DATE_FORMAT.DATE}
            value={mode === DesiredDeliveryDateMode.DATE ? value : null}
          />
        </Radio>
      </Col>
    </Row>
  );
};
