import React, {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {useLang} from '../../hooks/useLang';
import {AppRoutes} from '../../utils/route.helper';
import {NotFound} from '../error/NotFound';

export const AdminRoutes: FC = () => {
  const lang = useLang();

  return (
    <Routes>
      <Route path={'*'} element={<NotFound />} />
      <Route index element={<Navigate to={AppRoutes.customers.index({lang})} replace />} />
    </Routes>
  );
};
