import {hasValueInObject} from '../helpers/has-value-in-object';
import {modelToString} from '../helpers/model-to-string';
import {Base} from '../interfaces/base';

export abstract class BaseAbstractModel<MODEL extends Base> implements Base {
  public abstract readonly storableProperties: (keyof MODEL)[];
  public readonly searchableProperties: (keyof MODEL)[] = [];
  public readonly ignorableToStringProperties: (keyof BaseAbstractModel<MODEL>)[] = [];
  public id: string;

  protected constructor(props: Partial<Base>) {
    this.id = props.id || '';
  }

  public hasValue(value: string): boolean {
    return hasValueInObject({value, obj: this as unknown as MODEL, objProps: this.searchableProperties});
  }

  public toString(): string {
    return modelToString(this, [
      'storableProperties',
      'searchableProperties',
      'ignorableToStringProperties',
      ...this.ignorableToStringProperties,
    ]);
  }
}
