import {QueryDocumentSnapshot} from '@firebase/firestore';

import {UnsubscribeFacilityDocument} from '../../interfaces/unsubscribe-facility';
import {UnsubscribeFacilityModel} from '../../models/unsubscribe-facility';
import {BaseRepository} from './base';

export const COLLECTION_PATH_UNSUBSCRIBE_FACILITIES = 'unsubscribeFacilities';

export class UnsubscribeFacilityRepository extends BaseRepository<UnsubscribeFacilityDocument, UnsubscribeFacilityModel> {
  public constructor() {
    super({collectionPath: COLLECTION_PATH_UNSUBSCRIBE_FACILITIES});
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<UnsubscribeFacilityDocument>): UnsubscribeFacilityModel {
    const snapshotData = snapshot.data();

    return new UnsubscribeFacilityModel({
      ...snapshotData,
      id: snapshot.id,

      readyForPickupFrom: snapshotData.readyForPickupFrom?.toDate(),
      pickedUpAtTheLatest: snapshotData.pickedUpAtTheLatest?.toDate(),

      createdAt: snapshotData.createdAt?.toDate(),
    });
  }
}
