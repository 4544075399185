import {Spin} from 'antd';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';

interface FullScreenLoaderProps {
  active?: boolean;
}

export const FullScreenLoader: FC<PropsWithChildren<FullScreenLoaderProps>> = ({children, active = true}) => {
  const {t} = useTranslation();

  return active ? (
    children ? (
      <Spin tip={t('app.loading')}>{children}</Spin>
    ) : (
      <div className={'flex h-screen items-center justify-center'}>
        <Spin tip={t('app.loading')} />
      </div>
    )
  ) : (
    <>{children}</>
  );
};
