import {QueryDocumentSnapshot} from '@firebase/firestore';

import {NewOrderDocument} from '../../interfaces/new-order';
import {NewOrderModel} from '../../models/new-order';
import {BaseRepository} from './base';

export const COLLECTION_PATH_NEW_ORDER = 'newOrders';

export class NewOrderRepository extends BaseRepository<NewOrderDocument, NewOrderModel> {
  public constructor() {
    super({collectionPath: COLLECTION_PATH_NEW_ORDER});
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<NewOrderDocument>): NewOrderModel {
    const snapshotData = snapshot.data();

    return new NewOrderModel({
      ...snapshotData,
      id: snapshot.id,

      desiredDeliveryDate: snapshotData.desiredDeliveryDate?.toDate(),

      createdAt: snapshotData.createdAt?.toDate(),
    });
  }
}
