import {Base} from '../interfaces/base';
import {Customer} from '../interfaces/customer';
import {BaseAbstractModel} from './base.abstract';

export class CustomerModel extends BaseAbstractModel<CustomerModel> implements Customer {
  public readonly storableProperties: (keyof CustomerModel)[] = ['name', 'address', 'createdAt', 'updatedAt'];
  public name: Customer['name'];
  public address: Customer['address'];
  public createdAt: Customer['createdAt'];
  public updatedAt: Customer['updatedAt'];

  public constructor(props: Partial<Base> & Customer) {
    super(props);

    this.name = props.name;
    this.address = props.address;

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}
