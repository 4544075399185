import {getAuth} from '@firebase/auth';
import axios from 'axios';

import {CLOUD_FUNCTION_CONFIG, CloudFunction} from './callable-cloud-functions';
import {DEFAULT_EMULATOR_REGION} from './region';

const createAuthorizationKey = async () => ['Bearer', await getAuth().currentUser?.getIdToken(true)].join(' ');

export const cloudFunctionsRequest = () => {
  const getFunctionsURL = <FUNCTION_NAME extends CloudFunction>(name: FUNCTION_NAME) =>
    process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST
      ? [
          'http:/',
          process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST,
          process.env.REACT_APP_PROJECT_ID,
          DEFAULT_EMULATOR_REGION,
          CLOUD_FUNCTION_CONFIG[name].name,
        ].join('/')
      : [
          'https:/',
          `${CLOUD_FUNCTION_CONFIG[name].region}-${String(process.env.REACT_APP_PROJECT_ID)}.cloudfunctions.net`,
          CLOUD_FUNCTION_CONFIG[name].name,
        ].join('/');

  const instance = axios.create();

  instance.interceptors.request.use(async (config) => ({
    ...config,
    headers: {...config.headers, Authorization: await createAuthorizationKey()},
  }));

  const retrieveDocument = (id: string) =>
    instance.get<ArrayBuffer>(`${getFunctionsURL(CloudFunction.retrieveDocument)}`, {
      params: {id},
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    });

  return {
    retrieveDocument,
  };
};
