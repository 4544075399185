import {Descriptions, Typography} from 'antd';
import dayjs from 'dayjs';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {cloudFunctionsRequest} from '@wc-vermietung/library/helpers/cloud-functions/cloud-functions-request';
import {sortAlphabetical} from '@wc-vermietung/library/helpers/sorters';
import {Order} from '@wc-vermietung/library/interfaces/order';
import {DATE_FORMAT} from '@wc-vermietung/library/lib/date';

import {EmDashComponent} from '../../../../components/EmDash';
import {DefaultLayout} from '../../../../components/layouts/DefaultLayout';
import {OrderStatusTag} from '../../../../components/OrderStatusTag';
import {useStore} from '../../../../hooks/useStore';
import {openNotification} from '../../../../utils/notification.helper';
import {NotFound} from '../../../error/NotFound';
import {OrderPositions} from './partials/OrderPositions';
import {UnsubscribeFacilityModalButton} from './partials/UnsubscribeFacilityModalButton';

export const OrderDetail: FC = () => {
  const {t} = useTranslation();
  const {orderId} = useParams();

  const {isLoading, orders, customers} = useStore();

  const [isPdfLoading, setIsPdfLoading] = useState<boolean>();

  if (!isLoading && !orderId) {
    return <NotFound />;
  }

  const order = orders.find(({id}) => id === orderId);

  if (!isLoading && !order) {
    return <NotFound />;
  }

  const customer = customers.find(({id}) => id === String(order?.customerId));

  const downloadAttachment = async (orderAttachment: Order['attachments'][0]) => {
    setIsPdfLoading(true);

    const response = await cloudFunctionsRequest()
      .retrieveDocument(orderAttachment.id)
      .catch(() => null);

    if (!response) {
      void openNotification({
        type: 'error',
        description: t('message.order_attachment.unknown_error', {fileName: orderAttachment.fileName}),
        key: `order-attachment-unknown-error-${orderAttachment.id}`,
      });

      setIsPdfLoading(false);
      return;
    }

    const pdf = new Blob([response.data], {type: 'application/pdf'});

    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(pdf);
    tempLink.setAttribute('download', orderAttachment.fileName);
    tempLink.click();

    void openNotification({
      type: 'success',
      description: t('message.document_successfully_downloaded', {fileName: orderAttachment.fileName}),
      key: `order-attachment-document-successfully-downloaded-${orderAttachment.id}`,
    });

    setIsPdfLoading(false);
  };

  const filteredOrderItems = Object.values(order?.attachments || {}).filter(
    (orderAttachment) =>
      orderAttachment.comment.toLowerCase().includes('y') || orderAttachment.fileName.toLowerCase().includes('mietvertrag_'),
  );

  return (
    <DefaultLayout pageTitle={t('orders.detail.title', {no: orderId})} loading={isPdfLoading || isLoading}>
      <Descriptions size={'small'} className={'descriptions-vertical order-detail'}>
        <Descriptions.Item label={t('models.order.order_no')}>{order?.id}</Descriptions.Item>
        <Descriptions.Item label={t('models.order.construction_site_no')}>
          {order && (order.constructionSiteNo || <EmDashComponent />)}
        </Descriptions.Item>
        <Descriptions.Item label={t('models.order.toilet_no')}>{order && (order.toiletNo || <EmDashComponent />)}</Descriptions.Item>
        <Descriptions.Item label={t('models.order.status')}>
          {order?.statusOverall && <OrderStatusTag orderStatus={order.statusOverall} />}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.detail.data.responsible')}>
          {order && (order.userFullName || <EmDashComponent />)}
          {customer?.name && <>&nbsp;[{customer.name}]</>}
        </Descriptions.Item>

        <Descriptions.Item>&nbsp;</Descriptions.Item>

        <Descriptions.Item label={t('models.order.address')}>
          <p>{order?.address.supplement}</p>
          <p>{order?.address.street}</p>
          <p>
            {order?.address.zip && <>{order?.address.zip}&nbsp;</>}
            {order?.address.city}
          </p>
        </Descriptions.Item>
        {order?.shippedAt && (
          <Descriptions.Item label={t('models.order.delivered_at')}>
            {dayjs(order?.shippedAt).format(DATE_FORMAT.FULL_DATE_WRITTEN)}
          </Descriptions.Item>
        )}
        {!order?.shippedAt && order?.rentFrom && (
          <Descriptions.Item label={t('models.order.rent_from')}>
            {dayjs(order.rentFrom).format(DATE_FORMAT.FULL_DATE_WRITTEN)}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t('models.order.rent_to')}>
          {order && (order.rentTo ? dayjs(order.rentTo).format(DATE_FORMAT.FULL_DATE_WRITTEN) : t('orders.detail.data.rent_indeterminate'))}
        </Descriptions.Item>

        <Descriptions.Item>&nbsp;</Descriptions.Item>

        <Descriptions.Item label={t('models.order.service_frequency')}>
          {order?.serviceFrequency && t(`models.order.service_frequencies.${String(order.serviceFrequency)}`)}
        </Descriptions.Item>
        <Descriptions.Item label={t('models.order.comment')}>{order && (order.comment || <EmDashComponent />)}</Descriptions.Item>

        <Descriptions.Item>&nbsp;</Descriptions.Item>

        {filteredOrderItems.length && (
          <Descriptions.Item label={t('orders.detail.data.documents')}>
            {filteredOrderItems
              .sort((a, b) => sortAlphabetical(a.fileName, b.fileName))
              .map((v) => (
                <Typography.Link key={v.id} onClick={() => void downloadAttachment(v)}>
                  {v.fileName}
                </Typography.Link>
              ))}
          </Descriptions.Item>
        )}
      </Descriptions>

      {orderId && (
        <div className={'mt-10 flex justify-end'}>
          <UnsubscribeFacilityModalButton orderId={orderId} />
        </div>
      )}

      {orderId && (
        <div className={'mt-10'}>
          <OrderPositions orderId={orderId}></OrderPositions>
        </div>
      )}
    </DefaultLayout>
  );
};
