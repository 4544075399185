import {QueryDocumentSnapshot} from '@firebase/firestore';

import {UserDocument} from '../../interfaces/user';
import {UserModel} from '../../models/user';
import {BaseRepository} from './base';

export const COLLECTION_PATH_USERS = 'users';

export class UserRepository extends BaseRepository<UserDocument, UserModel> {
  public constructor() {
    super({collectionPath: COLLECTION_PATH_USERS});
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<UserDocument>): UserModel {
    const snapshotData = snapshot.data();

    return new UserModel({
      ...snapshotData,
      id: snapshot.id,

      createdAt: snapshotData.createdAt?.toDate(),
      updatedAt: snapshotData.updatedAt?.toDate(),
    });
  }
}
