import {FirebaseError} from '@firebase/util';
import {light} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Form, Input, Modal, Typography} from 'antd';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../../components/Button';
import {DefaultLayout} from '../../../components/layouts/DefaultLayout';
import {useAuth} from '../../../hooks/useAuth';
import {useLang} from '../../../hooks/useLang';
import {useRepository} from '../../../hooks/useRepository';
import {FirebaseAuthErrorCodes} from '../../../types/firebase';
import {getInvalidFieldNames} from '../../../utils/form.helper';
import {openMessage} from '../../../utils/message.helper';
import {REGEX} from '../../../utils/regex.helper';
import {AppRoutes} from '../../../utils/route.helper';

interface ChangePasswordForm {
  email: string;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const ChangePassword: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const lang = useLang();
  const {firebaseUser} = useAuth();
  const authRepository = useRepository('auth');

  const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);
  const [firebaseErrorCode, setFirebaseErrorCode] = useState<FirebaseAuthErrorCodes>();

  const [form] = Form.useForm<ChangePasswordForm>();
  Form.useWatch([], form);

  useEffect(() => {
    const invalidFields = getInvalidFieldNames(form);

    if (invalidFields.length) {
      void form.validateFields(invalidFields);
    }
  }, [form, lang]);

  const onChangePassword = (values: ChangePasswordForm) => {
    setChangePasswordInProgress(true);

    if (!firebaseUser) {
      throw new Error('Firebase user empty');
    }

    authRepository
      .updatePassword({user: firebaseUser, currentPassword: values.currentPassword, newPassword: values.newPassword})
      .then(() => {
        void openMessage({
          type: 'success',
          content: t('message.password_has_been_updated'),
          key: 'user-password-updated',
        });
        return navigate(AppRoutes.user.me({lang}));
      })
      .catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          const firebaseErrorCode = error.code as FirebaseAuthErrorCodes;

          switch (firebaseErrorCode) {
            // t('firebase.errors.auth/wrong-password')
            case 'auth/wrong-password':
              form.setFields([{name: 'currentPassword', errors: [t(`firebase.errors.${error.code}`)]}]);
              break;

            // t('firebase.errors.auth/weak-password')
            case 'auth/weak-password':
              form.setFields([{name: 'newPassword', errors: [t(`firebase.errors.${error.code}`)]}]);
              break;

            default:
              setFirebaseErrorCode(firebaseErrorCode);
          }
        }
      })
      .finally(() => setChangePasswordInProgress(false));
  };

  const onChangePasswordFailed = (errorInfo: ValidateErrorEntity<ChangePasswordForm>) => {
    console.error('Failed:', errorInfo);
  };

  const showModalToSendResetPasswordMail = ({email}: {email: string}) => {
    Modal.confirm({
      title: t('modal.send_reset_password_mail.title'),
      icon: <FontAwesomeIcon icon={light('question-circle')} className={'anticon'} />,
      content: t('modal.send_reset_password_mail.description', {email}),
      okText: t('actions.yes'),
      cancelText: t('actions.no'),
      onOk: () =>
        authRepository
          .sendPasswordResetEmail(email)
          .then(() => {
            void openMessage({
              type: 'success',
              content: t('message.reset_password_mail_sent'),
              key: 'reset-password-mail',
            });
          })
          .catch((e) => console.error(e)),
    });
  };

  return (
    <DefaultLayout pageTitle={t('actions.change_password')}>
      <Form
        form={form}
        onFinish={onChangePassword}
        onFinishFailed={onChangePasswordFailed}
        className={'max-w-sm'}
        layout={'vertical'}
        requiredMark={false}
      >
        <Form.Item hidden name={'email'} initialValue={firebaseUser?.email}>
          <Input autoComplete={'username'} />
        </Form.Item>

        <Form.Item
          label={
            <>
              {t('form.fields.current_password')}
              <>
                &nbsp;(
                <Typography.Link onClick={() => showModalToSendResetPasswordMail({email: firebaseUser?.email as string})}>
                  {t('actions.forgot_password')}?
                </Typography.Link>
                )
              </>
            </>
          }
          name={'currentPassword'}
          rules={[{required: true, message: t('form.errors.current_password_missing')}]}
        >
          <Input.Password autoComplete={'current-password'} />
        </Form.Item>

        <Form.Item
          label={t('form.fields.new_password')}
          name={'newPassword'}
          rules={[
            {required: true, message: t('form.errors.new_password_missing')},
            {
              pattern: REGEX.password,
              message: t('form.errors.password_weak'),
            },
          ]}
        >
          <Input.Password autoComplete={'new-password'} />
        </Form.Item>

        <Form.Item
          label={t('form.fields.new_password_confirm')}
          name={'newPasswordConfirm'}
          dependencies={['new-password']}
          rules={[
            ({getFieldValue}) => ({
              validator: (_, value) => {
                if (!getFieldValue('newPassword')) {
                  return Promise.reject(new Error(t('form.errors.new_password_for_confirmation_missing')));
                }

                if (!value) {
                  return Promise.reject(new Error(t('form.errors.new_password_confirmation_missing')));
                }

                if (getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('form.errors.new_password_confirmation_mismatched')));
              },
            }),
          ]}
        >
          <Input.Password autoComplete={'new-password'} />
        </Form.Item>

        <Form.Item className={'mt-16 mb-0'}>
          <Button htmlType={'submit'} className={'font-medium px-7'} loading={changePasswordInProgress} disabled={changePasswordInProgress}>
            {t('actions.change_password')}
          </Button>
        </Form.Item>

        {firebaseErrorCode && (
          <Alert
            className={'mt-8'}
            message={t(`firebase.errors.${firebaseErrorCode}`)}
            type={'error'}
            onClose={() => setFirebaseErrorCode(undefined)}
            showIcon
            closable
          />
        )}
      </Form>
    </DefaultLayout>
  );
};
