import {Table} from 'antd';
import React, {FC, PropsWithChildren} from 'react';

import {Order} from '@wc-vermietung/library/interfaces/order';

import {useStore} from '../../../../../hooks/useStore';
import {generateTableColumns, getDataIndexList, TableColumn, TableRow} from '../../../../../utils/table.helper';

type OrderPositionTableData = Order['positions'][0] & {no: number};
type OrderPositionTableRow = TableRow<OrderPositionTableData>;
type OrderPositionTableColumn = TableColumn<OrderPositionTableData>;

interface OrderPositionsProps {
  orderId: string;
}

export const OrderPositions: FC<PropsWithChildren<OrderPositionsProps>> = ({orderId}) => {
  const {isOrdersLoading, orders} = useStore();

  const order = orders.find(({id}) => id === orderId);

  if (!order) {
    return null;
  }

  const fields: OrderPositionTableColumn[] = [{dataIndex: 'no'}, {dataIndex: 'name'}, {dataIndex: 'code'}];
  const columns = generateTableColumns({fields, transPrefix: 'models.order.position'});
  const dataIndexList = getDataIndexList(fields);
  const dataSource = Object.entries(order.positions).map(([positionNo, model]) =>
    dataIndexList.reduce<OrderPositionTableRow>(
      (curr, dataIndex) => ({...curr, [dataIndex]: dataIndex === 'no' ? positionNo : model[dataIndex]}),
      {key: positionNo} as OrderPositionTableRow,
    ),
  );

  return (
    <Table
      className={'order-positions'}
      size={'small'}
      columns={columns}
      dataSource={dataSource}
      scroll={{x: 'max-content'}}
      loading={isOrdersLoading}
      pagination={false}
    />
  );
};
