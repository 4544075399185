import {QueryDocumentSnapshot} from '@firebase/firestore';

import {CustomerDocument} from '../../interfaces/customer';
import {CustomerModel} from '../../models/customer';
import {BaseRepository} from './base';

export const COLLECTION_PATH_CUSTOMERS = 'customers';

export class CustomerRepository extends BaseRepository<CustomerDocument, CustomerModel> {
  public constructor() {
    super({collectionPath: COLLECTION_PATH_CUSTOMERS});
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<CustomerDocument>): CustomerModel {
    const snapshotData = snapshot.data();

    return new CustomerModel({
      ...snapshotData,
      id: snapshot.id,

      createdAt: snapshotData.createdAt?.toDate(),
      updatedAt: snapshotData.updatedAt?.toDate(),
    });
  }
}
