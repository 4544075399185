import {notification} from 'antd';
import {ArgsProps} from 'antd/lib/notification';

interface NotificationProps extends Omit<ArgsProps, 'message'> {
  message?: ArgsProps['message'];
}

export const openNotification = ({duration = 20, ...args}: NotificationProps) => {
  return notification.open({
    message: '',
    duration,
    ...args,
  });
};
