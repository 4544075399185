type GroupByData = object;

type GroupBy<T extends GroupByData> = Record<string, T[]>;

type GroupBy2nd<T extends GroupByData> = Record<string, GroupBy<T>>;

export const groupBy = <T extends GroupByData, K extends keyof T>({data, groupKey}: {data: T[]; groupKey: K}): GroupBy<T> =>
  data.reduce<GroupBy<T>>(
    (r, v, _i, _a, k = v[groupKey]) => ((r[k as unknown as string] || (r[k as unknown as string] = []))?.push(v), r),
    {},
  );

export const groupByAndGroupBy = <T extends GroupByData, K extends keyof T>({
  data,
  groupKey,
  groupKey2nd,
}: {
  data: T[];
  groupKey: K;
  groupKey2nd: K;
}): GroupBy2nd<T> =>
  data.reduce<GroupBy2nd<T>>(
    (r, v, _i, _a, k = v[groupKey], k2 = v[groupKey2nd]) => (
      (
        r?.[k as unknown as string]?.[k2 as unknown as string]
        || ((r?.[k as unknown as string] || (r[k as unknown as string] = {}))[k2 as unknown as string] = [])
      )?.push(v),
      r
    ),
    {},
  );
