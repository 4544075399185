import React, {FC} from 'react';
import {Route, Routes} from 'react-router-dom';

import {PermissionGuard} from '../../components/PermissionGuard';
import {USER_ID_ROUTE_PATTERN} from '../../utils/route.helper';
import {NotFound} from '../error/NotFound';
import {ChangePassword} from './change-password/ChangePassword';
import {UserDetail} from './detail/UserDetail';
import {UserList} from './list/UserList';

export const UserRoutes: FC = () => (
  <>
    <Routes>
      <Route path={'*'} element={<NotFound />} />
      <Route
        index
        element={
          <PermissionGuard isAdmin>
            <UserList />
          </PermissionGuard>
        }
      />

      <Route path={`${USER_ID_ROUTE_PATTERN}/*`}>
        <Route path={'*'} element={<NotFound />} />

        <Route
          index
          element={
            <PermissionGuard>
              <UserDetail />
            </PermissionGuard>
          }
        />

        <Route path={'change-password'} element={<ChangePassword />} />
      </Route>
    </Routes>
  </>
);
