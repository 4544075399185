import {ColumnsType} from 'antd/lib/table';
import {ColumnType} from 'antd/lib/table/interface';
import {paramCase, snakeCase} from 'change-case';
import React from 'react';

import {i18n} from '../i18n/i18n';

export type TableData = Record<string, unknown>;

export type TableRow<TB = TableData> = {key: React.Key} & TB;

export type TableColumn<TD = TableData> = {
  dataIndex: keyof TD;
  transSuffix?: string;
  column?: ColumnType<TableRow<TD>>;
};

export const generateTableColumns = <TD>({
  fields,
  transPrefix,
}: {
  fields: TableColumn<TD>[];
  transPrefix: string;
}): ColumnsType<TableRow<TD>> =>
  fields.map(({dataIndex, transSuffix, column}) => ({
    title: i18n.t<string>(`${transPrefix.replace(/\.+$/, '')}.${transSuffix || snakeCase(String(dataIndex))}`),
    dataIndex: String(dataIndex),
    key: paramCase(String(dataIndex)),
    className: paramCase(String(dataIndex)),
    ...column,
  }));

export const getDataIndexList = <TD>(fields: TableColumn<TD>[]) =>
  fields.reduce<TableColumn<TD>['dataIndex'][]>((curr, prev) => [...curr, prev.dataIndex], []);
