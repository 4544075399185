import React, {FC} from 'react';
import {Route, Routes} from 'react-router-dom';

import {PermissionGuard} from '../../../components/PermissionGuard';
import {ORDER_ID_ROUTE_PATTERN} from '../../../utils/route.helper';
import {OrderDetail} from './detail/OrderDetail';
import {OrderList} from './list/OrderList';

export const OrderRoutes: FC = () => (
  <Routes>
    <Route
      index
      element={
        <PermissionGuard>
          <OrderList />
        </PermissionGuard>
      }
    />

    <Route
      path={ORDER_ID_ROUTE_PATTERN}
      element={
        <PermissionGuard>
          <OrderDetail />
        </PermissionGuard>
      }
    />
  </Routes>
);
