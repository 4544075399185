import {Analytics, initializeAnalytics} from '@firebase/analytics';
import {FirebaseApp, getApp, initializeApp} from '@firebase/app';
import {connectAuthEmulator, getAuth} from '@firebase/auth';
import {connectFirestoreEmulator, getFirestore} from '@firebase/firestore';
import {connectFunctionsEmulator, getFunctions} from '@firebase/functions';
import {connectStorageEmulator, getStorage} from '@firebase/storage';
import React, {createContext, FC, PropsWithChildren, useEffect, useState} from 'react';

import {DEFAULT_EMULATOR_REGION} from '@wc-vermietung/library/helpers/cloud-functions/region';

import {i18n} from '../i18n/i18n';

export interface FirebaseContextType {
  app?: FirebaseApp;
  initialized: boolean;
  analytics?: Analytics;
}

export const FirebaseContext = createContext<FirebaseContextType>({initialized: false});

const initializeApplication = (): void => {
  const options = {
    apiKey: process.env.REACT_APP_API_KEY ?? '',
    authDomain: process.env.REACT_APP_AUTH_DOMAIN ?? '',
    projectId: process.env.REACT_APP_PROJECT_ID ?? '',
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET ?? '',
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID ?? '',
    appId: process.env.REACT_APP_APP_ID ?? '',
    measurementId: process.env.REACT_APP_MEASUREMENT_ID ?? '',
  };

  const firebaseApp = initializeApp(options);

  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST) {
      const auth = getAuth();
      connectAuthEmulator(auth, `http://${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST || ''}`);
    }

    if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
      const firestore = getFirestore();
      const [firestoreHost, firestorePort] = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST?.split(':') as [string, number];
      connectFirestoreEmulator(firestore, firestoreHost, firestorePort);
    }

    if (process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST) {
      const functions = getFunctions(firebaseApp, DEFAULT_EMULATOR_REGION);
      const [functionsHost, functionsPort] = process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST?.split(':') as [string, number];
      connectFunctionsEmulator(functions, functionsHost, functionsPort);
    }

    if (process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST) {
      const storage = getStorage(firebaseApp);
      const [storageHost, storagePort] = process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST?.split(':') as [string, number];
      connectStorageEmulator(storage, storageHost, storagePort);
    }
  }
};

export const FirebaseProvider: FC<PropsWithChildren> = ({children}) => {
  const [contextValue, setContextValue] = useState<FirebaseContextType>({initialized: false});

  useEffect(() => {
    if (contextValue.initialized) {
      return;
    }

    initializeApplication();
    const analytics = initializeAnalytics(getApp());

    setContextValue({
      initialized: !!getApp().name,
      analytics,
    });
  }, [contextValue, contextValue.initialized]);

  useEffect(() => {
    if (!contextValue.initialized) {
      return;
    }

    getAuth().languageCode = i18n.resolvedLanguage;
  }, [contextValue, contextValue.initialized]);

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
};
