import {documentId, QueryConstraint, where} from '@firebase/firestore';
import React, {FC, PropsWithChildren, useEffect, useState} from 'react';

import {UserRole} from '@wc-vermietung/library/interfaces/user';
import {CustomerModel} from '@wc-vermietung/library/models/customer';
import {OrderModel} from '@wc-vermietung/library/models/order';
import {CustomerStatisticModel} from '@wc-vermietung/library/models/statistic';
import {UserModel} from '@wc-vermietung/library/models/user';
import {DOC_ID_CUSTOMER_STATISTICS} from '@wc-vermietung/library/repositories/client/statistic';

import {useAuth} from '../hooks/useAuth';
import {useLoadAllCollection} from '../hooks/useLoadAllCollection';
import {useLoadOneCollection} from '../hooks/useLoadOneCollection';

export interface StoreContextType {
  isLoading: boolean;

  isUsersLoading: boolean;
  isCustomersLoading: boolean;
  isOrdersLoading: boolean;
  isCustomerStatisticLoading: boolean;
  reloadOrders: () => void;

  users: UserModel[];
  customers: CustomerModel[];
  orders: OrderModel[];
  customerStatistic: CustomerStatisticModel | undefined;
}

export const StoreContext = React.createContext<StoreContextType>({
  isLoading: true,

  isUsersLoading: true,
  isCustomersLoading: true,
  isOrdersLoading: true,
  isCustomerStatisticLoading: true,

  reloadOrders: () => null,

  users: [],
  customers: [],
  orders: [],
  customerStatistic: undefined,
});

export const StoreProvider: FC<PropsWithChildren> = ({children}) => {
  const {user} = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const {data: users, isLoading: isUsersLoading} = useLoadAllCollection<UserModel>({
    repositoryName: 'user',
    user,
    requirements: {userRole: [UserRole.SUPERADMIN, UserRole.ADMIN]},
  });

  const {data: customers, isLoading: isCustomersLoading} = useLoadAllCollection<CustomerModel>({
    repositoryName: 'customer',
    user,
    requirements: {isLoggedIn: true},
    queryConstraints: [
      user && ![UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role) && where(documentId(), '==', user.customerId),
    ].filter<QueryConstraint>((v): v is QueryConstraint => !!v),
  });

  const {
    data: orders,
    isLoading: isOrdersLoading,
    forceLoading: reloadOrders,
  } = useLoadAllCollection<OrderModel>({
    repositoryName: 'order',
    user,
    requirements: {isLoggedIn: true},
    queryConstraints: [
      user && ![UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role) && where('customerId', '==', user.customerId),
      user?.isUser && where('userId', '==', user.id),
    ].filter<QueryConstraint>((v): v is QueryConstraint => !!v),
  });

  const {data: customerStatistic, isLoading: isCustomerStatisticLoading} = useLoadOneCollection<CustomerStatisticModel>({
    repositoryName: 'customerStatistic',
    id: DOC_ID_CUSTOMER_STATISTICS,
    user,
    requirements: {userRole: [UserRole.SUPERADMIN, UserRole.ADMIN]},
  });

  useEffect(() => {
    if (!(isUsersLoading || isCustomersLoading || isOrdersLoading || isCustomerStatisticLoading)) {
      setIsLoading(false);
    }
  }, [isUsersLoading, isCustomersLoading, isOrdersLoading, isCustomerStatisticLoading]);

  return (
    <StoreContext.Provider
      value={{
        isLoading,

        isUsersLoading,
        isCustomersLoading,
        isOrdersLoading,
        isCustomerStatisticLoading,

        reloadOrders,

        users,
        customers,
        orders,
        customerStatistic,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
