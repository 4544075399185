import {Image} from 'antd';
import {ImageProps} from 'rc-image/lib/Image';
import React, {FC, HTMLAttributes} from 'react';
import {Link} from 'react-router-dom';

import logo from '../assets/img/logo.svg';

type LogoImageProps = Pick<HTMLAttributes<HTMLElement>, 'className'> &
  Pick<ImageProps, 'rootClassName'> & {noLink?: boolean; linkClassName?: HTMLAttributes<HTMLElement>['className']};

export const LogoImage: FC<LogoImageProps> = ({noLink, linkClassName, ...props}) =>
  noLink ? (
    <Image {...props} src={logo} alt={'logo'} preview={false} />
  ) : (
    <Link className={linkClassName} to={'/'}>
      <Image {...props} src={logo} alt={'logo'} preview={false} />
    </Link>
  );
