import React, {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {PermissionGuard} from '../../components/PermissionGuard';
import {useAuth} from '../../hooks/useAuth';
import {CUSTOMER_ID_ROUTE_PATTERN} from '../../utils/route.helper';
import {NotFound} from '../error/NotFound';
import {CustomerList} from './list/CustomerList';
import {OrderRoutes} from './order/OrderRoutes';

export const CustomerRoutes: FC = () => {
  const {user} = useAuth();

  return (
    <Routes>
      <Route path={'*'} element={<NotFound />} />
      <Route
        index
        element={
          user ? (
            user.isAdmin ? (
              <PermissionGuard isAdmin>
                <CustomerList />
              </PermissionGuard>
            ) : (
              <Navigate to={`${user.customerId}/orders`} replace />
            )
          ) : (
            <NotFound />
          )
        }
      />

      <Route path={`${CUSTOMER_ID_ROUTE_PATTERN}/*`}>
        <Route path={'*'} element={<NotFound />} />
        <Route index element={<Navigate to={'orders'} replace />} />

        <Route path={'orders/*'} element={<OrderRoutes />} />
      </Route>
    </Routes>
  );
};
